import { applyMiddleware, createStore, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootWatcher } from './sagas';

const sagaMiddleware = createSagaMiddleware()
const rootReducer = combineReducers(reducers)

export const store = createStore(
    rootReducer, 
    composeWithDevTools(
        applyMiddleware(sagaMiddleware)
    )
)

sagaMiddleware.run(rootWatcher)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;