import { FieldInputProps } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import React from "react";
import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en';
import { IFieldFront } from "../../../models/IField";

interface DatePickerNotFormikProps {
  field: FieldInputProps<any>;
  input: IFieldFront;
}

const DatePickerNotFormik: FC<DatePickerNotFormikProps> = ({
  field,
  input,
}) => {
  const [datepicker, setDatepicker] = useState<AirDatepicker | null>(null);

  let dp = useRef<any>({});


  useEffect(() => {
     dp.current  = new AirDatepicker(`#${field.name}`, {
      locale: localeEn,
      selectedDates: [new Date(field.value)],
      autoClose: true,
      onRenderCell({ date, cellType }) {
        if (cellType === 'day') {
          if (date.getDay() === 0 || date.getDay() === 6) {
            return {
              disabled: true
            }
          }
        }
      },
      onSelect: ({ date }: { date: Date | Date[] }) => {
        field.onChange(date || '')
      },
    })
    dp.current.setViewDate(new Date(field.value))
    // setDatepicker(datepickerLocal)



  }, [])

  useEffect(() => {
    dp.current.update({ selectedDates: field.value });
  }, [field.value])

  const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 10) {
      return;
    };
    const date: Date | string = new Date(e.target.value);

    if (!date && !datepicker) return;

    if (date instanceof Date && date.getTime()) {
      datepicker?.setViewDate(date)
      datepicker?.selectDate(date)
      field.onChange(date || '')
    }
  }

  return (
    <div className="react-datepicker__input-container">
      <input
        onChange={handleChangeDate}
        placeholder={input.placeholder}
        id={field.name}
      />
    </div>
  );
};

export default DatePickerNotFormik;