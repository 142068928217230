import { ITransaction } from "../../../models/IFinances";
import { IUserFinances, IUserGlobal } from "../../../models/IUser";

export interface UserState {
    user: IUserGlobal | null,
    finances: IUserFinances | null,
}

export enum UserActionEnum {
    FETCH_USER = "FETCH_USER",
    UPDATE_USER = "UPDATE_USER",
    FETCH_FINANCES = "FETCH_FINANCES",
    UPDATE_FINANCES = "UPDATE_FINANCES",
    CREATE_TRANSACTION = "CREATE_TRANSACTION",
}
export interface FetchUser {
    type: UserActionEnum.FETCH_USER;
    payload: number
}
export interface UpdateUser {
    type: UserActionEnum.UPDATE_USER;
    payload: IUserGlobal | null;
}
export interface UpdateFinances {
    type: UserActionEnum.UPDATE_FINANCES;
    payload: IUserFinances | null;
}
export interface CreateTransaction {
    type: UserActionEnum.CREATE_TRANSACTION;
    payload: ITransaction;
}
export interface FetchFinances {
    type: UserActionEnum.FETCH_FINANCES;
    payload: number,
}

export type UserAction =
    UpdateUser |
    UpdateFinances