import React from 'react'
import { Link } from 'react-router-dom'
import { ExtraRouteNames } from '../../router/Extra'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-body">
                <ul className="left-panel list-inline mb-0 p-0">
                    <li className="list-inline-item"><Link to="/dashboard/extra/privacy-policy">Privacy Policy</Link></li>
                    <li className="list-inline-item"><Link to={ExtraRouteNames.TERMS_OF_SERVICE}>Terms of Use</Link></li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer
