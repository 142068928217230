import { IFieldError } from "../../../models/IField";
import * as types from "./types";

export const AppActionCreators = {
    setErrors: (payload: IFieldError): types.setErrors =>
        ({ type: types.AppActionEnum.SET_ERRORS, payload }),
    handleLoader: (payload: boolean): types.handleLoader =>
        ({ type: types.AppActionEnum.HANDLE_LOADER, payload }),
    handleShowModal: (payload: types.IShowModal): types.handleShowModal =>
        ({ type: types.AppActionEnum.HANDLE_SHOW_MODAL, payload }),
    handleHideModal: (payload: string): types.handleHideModal =>
        ({ type: types.AppActionEnum.HANDLE_HIDE_MODAL, payload }),
}
