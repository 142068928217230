//router
import IndexRouters from "./router/index"
import { useEffect } from 'react'

//scss
import "./assets/scss/hope-ui.scss"
import AuthModal from "./components/uikit/AuthModal";
import { useActions } from "./hooks/useActions";
import { getCookie } from './plugins/helpers';

function App() {
  const { handleLoader, loginInit } = useActions()

  useEffect(() => {
    const token = getCookie('auth_token');
    if (token) {
      loginInit()
    } else {
      handleLoader(false);
    }
  }, []);

  return (
    <div className="App">
      <AuthModal />
      <IndexRouters />
    </div>
  );
}

export default App;
