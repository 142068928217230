import React, { useEffect } from 'react'

//header
import Header from '../components/dashboard/header'
//sidebar
import Sidebar from '../components/dashboard/sidebar'
//footer
import Footer from '../components/dashboard/footer'
//default 
import RouterDashboard from '../router/Dashboard'
import SubHeader from '../components/dashboard/sub-header'

const Dashboard = () => {
    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0), 100)
    }, [])

    return (
        <>
            <Sidebar />
            <main className="main-content">
                <div className="position-relative">
                    <Header />
                    <SubHeader />
                </div>
                <div className="conatiner-fluid content-inner mt-n5 py-0">
                    <RouterDashboard />
                </div>
                <Footer />
            </main>
        </>
    )
}

export default Dashboard;
