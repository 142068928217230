import { IFieldError } from "../../../models/IField";

export interface IShowModal {
    title: string,
    description?: string,
    image?: string,
}
export interface IModal extends IShowModal {
    value: string,
}
export interface AppState {
    errors: any,
    showLoader: boolean,
    isShowModal: boolean,
    modalDescription?: string,
    modalValue: string,
    modalTitle: string,
    modalImage: string | undefined,
}

export enum AppActionEnum {
    SET_ERRORS = "SET_ERRORS",
    HANDLE_LOADER = "HANDLE_LOADER",
    HANDLE_SHOW_MODAL = "HANDLE_SHOW_MODAL",
    HANDLE_HIDE_MODAL = "HANDLE_HIDE_MODAL",
}

export interface setErrors {
    type: AppActionEnum.SET_ERRORS;
    payload: IFieldError,
}
export interface handleLoader {
    type: AppActionEnum.HANDLE_LOADER;
    payload: boolean,
}
export interface handleShowModal {
    type: AppActionEnum.HANDLE_SHOW_MODAL;
    payload: IShowModal,
}
export interface handleHideModal {
    type: AppActionEnum.HANDLE_HIDE_MODAL;
    payload: string,
}

export type AppAction =
    setErrors |
    handleLoader |
    handleShowModal |
    handleHideModal