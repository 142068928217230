import { Button, Card } from 'react-bootstrap'
import React, { FC } from 'react';
import { ILocation, TypeBookNow } from '../../../models/IBookNow';

import locationImage from '../../../assets/images/location.png';
import { notification } from 'antd';
import { messages } from '../../../constants';
import { useActions } from '../../../hooks/useActions';

// icons
import peopleIcon from '../../../assets/images/icons/team.svg'
import buildIcon from '../../../assets/images/icons/Build.svg'

interface CardOfLocationProps {
  location: ILocation
}

const CardOfLocation: FC<CardOfLocationProps> = ({ location }) => {
  const { sendRequestByService, selectService } = useActions();

  const handleSelect = () => {
    const key = `open${Date.now()}`;

    const handleRequest = () => {
      sendRequestByService(location.name);
      notification.close(key)
    }

    const btn = (
      <Button variant="primary" onClick={handleRequest}>
        Send request
      </Button>
    );

    switch (location.id) {
      case 2:
        notification.info({
          message: messages.VIB2,
          btn,
          key
        })
        break;

      default:
        selectService({
          data: location,
          type: TypeBookNow.BUILDINGS
        })
        break;
    }
  }

  return (
    <div className="card_of_location">
      <Card className="mb-4 rounded-3 shadow-sm">
        <Card.Body>
          <div>
            <img className="card_of_location__image" src={locationImage} alt="location" />
            <Card.Title as="h2" className="card_of_location__name">
              {location.name}
            </Card.Title>
            <p className="card_of_location__description">{location.description}</p>
            <div className="card_of_location__info_block">
              <div>
                <img src={peopleIcon} alt="people" />
                <span className="card_of_service__info">{location.participants}</span>
              </div>
              <div>
                <img src={buildIcon} alt="build" />
                <span className="card_of_service__info">{location.services.join(' • ')}</span>
              </div>
            </div>
          </div>
          <Button
            variant="primary"
            onClick={handleSelect}
          >
            Book Now
            </Button>
        </Card.Body>
      </Card>
    </div>
  )
}

export default CardOfLocation;