import axios, { AxiosInstance } from "axios";
import Cookies from 'universal-cookie';
import { sha256 } from 'js-sha256';

export const handleDisableButton = (ref: React.RefObject<HTMLButtonElement>, time?: number) => {
    ref.current?.setAttribute('disabled', 'true')
    setTimeout(() => {
        ref.current?.removeAttribute("disabled");
    }, (time || 2) * 1000);
}

export const saveToCookies = (key: string, value: string) => {
    const cookies = new Cookies();
    cookies.set(key, value, { path: '/' });
}
// localStorage.setItem(key, value);
export const removeToCookies = (key: string) => {
    const cookies = new Cookies();
    cookies.remove(key, {path: "/"})
    localStorage.removeItem(key);
}
// localStorage.removeItem(key);
export const getCookie = (key: string): string => {
    const cookies = new Cookies();
    const result: string = localStorage.getItem(key) || cookies.get(key) || ''
    return result;
}
// localStorage.getItem(key)

export const Axios = (): AxiosInstance => {
    const token = getCookie('auth_token');
    const instance: AxiosInstance = axios.create({
        headers: { 'Authorization': token }
    });

    return instance;
}

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);

export function mergeHashToHeaders(object: any) {
    const salt = 'a5AwPbjQQVMlEF2RBG5h992LzQ3eHmZD'

    const filteredKeys = Object.keys(object).sort();

    const filteredObj = filteredKeys.reduce((prev, curr) => ({
        ...prev,
        [curr]: object[curr]
    }), {})

    let result = filteredKeys.join('');
    result += Object.values(filteredObj).join('');
    result += salt;

    const token = getCookie('auth_token');
    const instance: AxiosInstance = axios.create({
        headers: {
            Authorization: token,
            shd: sha256(result),
        }
    });

    return instance;
}

export const renderDateAndTime = (value: number) => {
    const time = +value;
    if (!time || typeof time === 'string') return '0';
    const formatterTime = new Intl.DateTimeFormat("en", { timeStyle: 'short' });
    const formatterDate = new Intl.DateTimeFormat("en", { month: 'short', day: 'numeric' });
    return formatterDate.format(new Date(+time * 1000)) + ', ' + formatterTime.format(new Date(+time * 1000))
}

export const renderDateAndYear = (value: number) => {
    const time = +value;
    if (!time || typeof time === 'string') return '0';
    const formatterDate = new Intl.DateTimeFormat("en", { month: 'short', day: 'numeric', year: "numeric" });
    return formatterDate.format(new Date(+time * 1000))
}