import { useState, useEffect, useRef } from 'react'
import { Row, Col, Image, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import auth5 from '../../../assets/images/auth/05.png'
import { useActions } from '../../../hooks/useActions'
import { GetFields } from '../../../apis/fields';
import { AuthRouteNames } from '../../../router/Auth';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { IRoles } from '../../../models/IRoles';
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { ICategoryField, IFieldFront } from '../../../models/IField'
import FieldWithValidation from '../../../components/uikit/FieldWithValidation';
import FieldBlock from '../../../components/uikit/FieldBlock';
import { handleDisableButton } from '../../../plugins/helpers';

const SignUp = () => {
  const { register, setAuth, handleLoader, setTotalStep } = useActions()

  const { user } = useTypedSelector(state => state.user)
  const { stepOfRegist, totalStep } = useTypedSelector(state => state.auth)
  const { errors, modalValue } = useTypedSelector(state => state.app)

  const [firstStepFields, setFirstStepFields] = useState<any>(null)

  const [initialValues, setInitialValues] = useState<any>(null);
  const [validationSchema, setValidationSchema] = useState<any>(null);
  const [fields, setFields] = useState<IFieldFront[] | null>(null)
  const [fieldsByCategories, setFieldsByCategories] = useState<ICategoryField[] | null>(null)
  const [formikActions, setFormicActions] = useState<any>(null);

  const formButtonRef = useRef(null);

  useEffect(() => {
    handleLoader(true);
  }, [])

  useEffect(() => {
    if (formikActions && errors && Object.values(errors).length) {
      formikActions.setErrors(errors)
    }
  }, [errors]);

  useEffect(() => {
    if (totalStep && stepOfRegist > totalStep) {
      setAuth();
      return;
    }
    fetchFields()
  }, [stepOfRegist]);


  useEffect(() => {
    if (!firstStepFields || !modalValue) return;
    register({
      step: stepOfRegist,
      role_id: IRoles.GUEST,
      fields: {
        ...firstStepFields,
        mail_code: modalValue,
      },
    })
  }, [modalValue])

  const fetchFields = async () => {
    const res = await GetFields(stepOfRegist)

    if (!res) {
      return;
    }

    if (stepOfRegist === 2) {
      setFieldsByCategories(res.sortedFieldsByCategory)
    } else {
      setFields(res.sortedFields);
    }
    setValidationSchema(res.validations);
    setTotalStep(res.totalStep)
    setInitialValues(res.initialValues)
    formikActions && formikActions.resetForm({ values: res.initialValues });

    handleLoader(false);
  }

  const handleRegister = async (values: any, actions: any) => {
    setFormicActions(actions);
    handleDisableButton(formButtonRef)

    if (stepOfRegist === 1) {
      setFirstStepFields(values);
      register({
        step: stepOfRegist,
        role_id: IRoles.GUEST,
        fields: values,
      })
    } else {
      register({
        step: stepOfRegist,
        role_id: IRoles.GUEST,
        fields: {
          ...values,
          hash: user?.hash,
        },
        user_id: user?.user_id
      })
    }
  }

  const schema = Yup.object().shape(validationSchema);

  return (
    <section className="login-content">
      <Row className="m-0 align-items-center bg-white vh-100">
        <div className="col-md-6 d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
          <Image src={auth5} className="Image-fluid gradient-main animated-scaleX" alt="images" />
        </div>
        <Col md="6">
          <Row className="justify-content-center">
            <Col md="10">
              <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                <Card.Body>
                  <h2 className="mb-2 text-center">Sign Up</h2>
                  <p className="text-center">Create your account.</p>
                  {initialValues &&
                    <Formik
                      initialValues={initialValues}
                      validationSchema={schema}
                      onSubmit={(values, actions) => handleRegister(values, actions)}
                    >
                      <Form>
                        {stepOfRegist === 2
                          ? <Row>
                            {fieldsByCategories && fieldsByCategories.map((category: ICategoryField) =>
                              <FieldBlock key={category.id} category={category} />
                            )}
                          </Row>
                          : <Row>
                            {fields && fields.map((field: IFieldFront) =>
                              <FieldWithValidation key={field.id} field={field} />
                            )}
                          </Row>
                        }
                        <div className="d-flex justify-content-center">
                          <Button
                            type="submit"
                            variant="primary"
                            ref={formButtonRef}
                          >
                            Next
                          </Button>
                        </div>
                      </Form>
                    </Formik>
                  }
                  <p className="mt-3 text-center">
                    Already have an Account <Link to={AuthRouteNames.SIGN_IN} className="text-underline">Sign In</Link>
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  )
}

export default SignUp