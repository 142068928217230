import React, { FC } from "react";
import { Row } from "react-bootstrap";
import { ICategoryField, IFieldFront } from "../../models/IField";
import FieldWithValidation from "./FieldWithValidation";

interface FieldBlockProps {
  category: ICategoryField,
}

const FieldBlock: FC<FieldBlockProps> = ({ category }: FieldBlockProps) => {
  return (
    <div className="field-block">
      <p className="field-block__title">{category.name}</p>
      <Row>
        {category.fields && category.fields.map((field: IFieldFront) =>
          <FieldWithValidation key={field.id} field={field} />
        )}
      </Row>
    </div>
  )
}

export default FieldBlock;