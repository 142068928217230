import MaskedInput from "react-text-mask";

const cvvCardNumber = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]?/];

const CvvCardNumber = ({ field }: any) => {
  return (
    <MaskedInput
      mask={cvvCardNumber}
      guide={true}
      className="input__field"
      placeholderChar="_"
      showMask
      {...field}
    />
  );
};

export default CvvCardNumber;
