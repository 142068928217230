import * as types from "./types";
import { IUserLogin, IUserRegister, IUserResetPassword } from "../../../models/IUser";
import { THistory } from "../../../models/IRoute";

export const AuthActionCreators = {
    login: (payload: { data: IUserLogin, history: THistory }): types.Login =>
        ({ type: types.AuthActionEnum.LOGIN, payload }),
    logout: (): types.Logout =>
        ({ type: types.AuthActionEnum.LOGOUT }),
    loginInit: (): types.LoginInit =>
        ({ type: types.AuthActionEnum.LOGIN_INIT }),
    login2Auth: (payload: boolean): types.Login2Auth =>
        ({ type: types.AuthActionEnum.LOGIN_2_AUTH, payload }),
    setAuth: (payload?: boolean): types.SetAuth =>
        ({ type: types.AuthActionEnum.SET_AUTH, payload }),
    setStepOfRegist: (payload: number): types.SetStepOfRegist =>
        ({ type: types.AuthActionEnum.SET_STEP_OF_REGIST, payload }),
    setTotalStep: (payload: number): types.SetTotalStep =>
        ({ type: types.AuthActionEnum.SET_TOTAL_STEP, payload }),
    register: (payload: IUserRegister): types.Register =>
        ({ type: types.AuthActionEnum.REGISTER, payload }),
    resetPassword: (payload: { data: { mail: string }, history: THistory }): types.ResetPassword =>
        ({ type: types.AuthActionEnum.RESET_PASSWORD, payload }),
    updatePassword: (payload: { data: IUserResetPassword, history: THistory }): types.UpdatePassword =>
        ({ type: types.AuthActionEnum.UPDATE_PASSWORD, payload }),
}
