import { Button, Card, Row } from "react-bootstrap";
import React, { FC, useEffect, useState, useRef } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { PaymentModalFields } from "../../../constants";
import { createRulesForField } from "../../../apis/fields";
import { ObjectShape } from "yup/lib/object";
import FieldWithValidation from "../../uikit/FieldWithValidation";
import { TypesOfInput } from "../../../models/IField";
import { handleDisableButton } from "../../../plugins/helpers";

interface IInitialValues {
  card_holder: string;
  card_number: string;
  expiration_date: string;
  cvv: string;
  agree: string;
}

interface PaymentBlockProps {
  submit: (values: IInitialValues) => void;
}

const PaymentBlock: FC<PaymentBlockProps> = ({ submit }) => {
  const [validations, setValidations] = useState<ObjectShape>({});
  const formButtonRef = useRef(null);
  const { fetchPaymentSystems, selectOrder } = useActions();
  const { paymentSystems } = useTypedSelector((state) => state.profile);

  const PaymentSystemsForRender = paymentSystems?.deposit.filter(
    (item) => !!item.icon
  );

  useEffect(() => {
    fetchPaymentSystems();
    generateValidations();
  }, []);

  const handleSubmit = (values: IInitialValues) => {
    handleDisableButton(formButtonRef);
    submit({
      ...values,
      card_number: values.card_number.replace(/\s/g, ""),
      cvv: values.cvv.replace(/[^\d]/g, ""),
    });
  };

  const initialValues = {
    card_holder: "",
    card_number: "",
    expiration_date: "",
    cvv: "",
    agree: "",
  };

  const generateValidations = () => {
    const listOfFields = PaymentModalFields.map((field) => field.id);
    const schema = createRulesForField(listOfFields, PaymentModalFields);
    setValidations(schema);
  };

  const schema = Yup.object().shape({
    ...validations,
    card_number: Yup.string()
      .transform((value) => value.replace(/\s/g, ""))
      .matches(
        /^((5[1-5][0-9]{14})|(2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12})))$|^(4[0-9]{12}(?:[0-9]{3})?)$|^3[47][0-9]{13}$|^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/,
        "Card is invalid"
      )
      .required("Card number is required"),
    cvv: Yup.string()
      .transform((value) => value.replace(/[^\d]/g, ""))
      .matches(/^[0-9]{3,4}$/, "CVV is invalid")
      .required("CVV is required"),
    agree: Yup.string().required("You must confirm agreement"),
  });

  return (
    <Card className="rounded-3 shadow-sm">
      <Card.Body>
        <Card.Title as="h2" className="payment-block__name">
          Payment
        </Card.Title>
        <div className="payment-block__services">
          {PaymentSystemsForRender?.map((system) => (
            <div key={system.payment_id} className="payment-block__service">
              <img src={system.icon} alt="payment method" />
            </div>
          ))}
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => handleSubmit(values)}
        >
          <Form>
            <Row>
              {PaymentModalFields.map((field) => (
                <FieldWithValidation key={field.id} field={field} />
              ))}
            </Row>
            <div className="payment-block__description">
              Your personal data will be used to process your order, support
              your experience throughout this website, and for other purposes
              described in our Privacy Policy.
            </div>
            <FieldWithValidation
              field={{
                id: "agree",
                required: true,
                size: 12,
                type: TypesOfInput.CHECKBOX,
                category_id: 1,
                title:
                  "I have read and agree to the website Terms And Conditions",
              }}
            />
            <div className="payment-block__buttons">
              <Button
                type="submit"
                variant="outline-primary"
                className="payment-block__btn--back"
                onClick={() => selectOrder(null)}
              >
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.3681 8.12891H5.2259L10.6978 3.37891C10.7853 3.30234 10.7321 3.16016 10.6165 3.16016H9.23371C9.17277 3.16016 9.11496 3.18203 9.06965 3.22109L3.16496 8.34453C3.11088 8.39141 3.06751 8.44936 3.03778 8.51447C3.00806 8.57957 2.99268 8.65031 2.99268 8.72188C2.99268 8.79344 3.00806 8.86418 3.03778 8.92928C3.06751 8.99439 3.11088 9.05234 3.16496 9.09922L9.10402 14.2539C9.12746 14.2742 9.15559 14.2852 9.18527 14.2852H10.615C10.7306 14.2852 10.7837 14.1414 10.6962 14.0664L5.2259 9.31641H14.3681C14.4368 9.31641 14.4931 9.26016 14.4931 9.19141V8.25391C14.4931 8.18516 14.4368 8.12891 14.3681 8.12891Z"
                    fill="#3A57E8"
                  />
                </svg>
                Back
              </Button>
              <Button type="submit" variant="primary" ref={formButtonRef}>
                Book Now
              </Button>
            </div>
          </Form>
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default PaymentBlock;
