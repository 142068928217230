import React, { useEffect, useState } from 'react'
import { Row, Col, Nav, Tab } from 'react-bootstrap'
import Card from '../../../components/Card'

// img
import PaymentModal from '../../../components/uikit/PaymentModal'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { useActions } from '../../../hooks/useActions'
import { TypeOfPayments } from '../../../models/IFinances'
import TransactionsTable from '../../../components/uikit/TransactionsTable'

const Transactions = () => {
  const [tabKey, setTabKey] = useState<TypeOfPayments>(TypeOfPayments.DEPOSIT);
  const { finances, user } = useTypedSelector(state => state.user)
  const { fetchFinances, handleLoader } = useActions()
  const { repeatingTransaction } = useTypedSelector(state => state.profile)

  useEffect(() => {
    if (!user) return;
    handleLoader(true)
    fetchFinances(user?.user_id)
  }, [])

  useEffect(() => {
    if (!repeatingTransaction) return;

    if (repeatingTransaction.type.toLocaleLowerCase() === TypeOfPayments.DEPOSIT) {
      setTabKey(TypeOfPayments.DEPOSIT)
    } else if (repeatingTransaction.type.toLocaleLowerCase() === TypeOfPayments.WITHDRAWAL) {
      setTabKey(TypeOfPayments.WITHDRAWAL)
    }
  }, [repeatingTransaction])

  return (
    <Row>
      <Tab.Container defaultActiveKey="first">
        <Col lg="12">
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg="8">
                  <Col>
                    <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                      <span className="me-2">Total Amount</span>
                      <span> - {finances?.balance}$</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                      <span className="me-2">Available for withdrawal</span>
                      <span> - {finances?.balance}$</span>
                    </div>
                  </Col>
                </Col>
                <Col lg="4" className="d-flex justify-content-end">
                  <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab">
                    <Nav.Item as="li" onClick={() => setTabKey(TypeOfPayments.WITHDRAWAL)}>
                      <Nav.Link className={`${tabKey === TypeOfPayments.WITHDRAWAL ? 'active' : ''}`}>Withdraw</Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" onClick={() => setTabKey(TypeOfPayments.DEPOSIT)}>
                      <Nav.Link className={`${tabKey === TypeOfPayments.DEPOSIT ? 'active' : ''}`}>Deposit</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="12">
          <PaymentModal type={tabKey} />
        </Col>
      </Tab.Container>
      <Col sm="12">
        <TransactionsTable />
      </Col>
    </Row>
  )
}

export default Transactions;