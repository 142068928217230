import Index from '../views/dashboard/index'
import { Switch, Route } from 'react-router-dom'
// user
import UserSecurity from '../views/dashboard/user/security';
// table
import Transactions from '../views/dashboard/finances/transactions';
import PaymentSystems from '../views/dashboard/finances/payment-systems';

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";
//Special Pages
import BookNow from '../views/dashboard/special-pages/book-now';
import Calender from '../views/dashboard/special-pages/calender';
import { IRoute } from '../models/IRoute';
import React from 'react';
import ProfileView from '../components/dashboard/profile-view';

export enum DashboardRouteNames {
  DASHBOARD = '/dashboard',

  // Users
  USER_PROFILE = '/dashboard/user/profile',
  USER_SECURITY = '/dashboard/user/security',
  USER_IDENTIFICATION = '/dashboard/user/identification',

  // Finances
  TRANSACTIONS = "/dashboard/transactions",
  PAYMENT_MET = "/dashboard/payment-methods",

  PRICING = "/dashboard/pricing",
  CALENDAR = "/dashboard/calender",

  // Services
  SERVICE_BOOK_NOW = "/dashboard/book-now",
}

export const dashboardRoutes: IRoute[] = [
  { path: DashboardRouteNames.DASHBOARD, exact: true, component: Index },

  { path: DashboardRouteNames.USER_PROFILE, exact: true, component: () => <ProfileView typeOfPage={1} /> },
  { path: DashboardRouteNames.USER_SECURITY, exact: true, component: UserSecurity },
  { path: DashboardRouteNames.USER_IDENTIFICATION, exact: true, component: () => <ProfileView typeOfPage={3} /> },

  { path: DashboardRouteNames.TRANSACTIONS, exact: true, component: Transactions },
  { path: DashboardRouteNames.PAYMENT_MET, exact: true, component: PaymentSystems },

  { path: DashboardRouteNames.CALENDAR, exact: true, component: Calender },

  { path: DashboardRouteNames.SERVICE_BOOK_NOW, exact: true, component: BookNow },
]

const Dashboard = () => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Switch>
          {dashboardRoutes.map(route =>
            <Route path={route.path}
              exact={route.exact}
              component={route.component}
              key={route.path}
            />
          )}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default Dashboard;
