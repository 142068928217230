import { Switch, Route } from 'react-router-dom'
import { IRoute } from '../models/IRoute'

// errors
import Error404 from '../views/dashboard/errors/error404'

export enum ErrorRouteNames {
  ERROR_404 = '/errors/error404',
}

export const errorRoutes: IRoute[] = [
  { path: ErrorRouteNames.ERROR_404, exact: true, component: Error404 },
]

const Errors = () => {
  return (
    <Switch>
      {errorRoutes.map(route =>
        <Route path={route.path}
          exact={route.exact}
          component={route.component}
          key={route.path}
        />
      )}
    </Switch>
  )
}

export default Errors;
