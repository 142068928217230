import { put, select, takeLatest } from 'redux-saga/effects'
import { FetchUser } from '../../apis/user';
import { ICodes } from '../../models/ICodes';
import { CreateTransaction, FetchFinances, FetchUser as TFetchUser, UserActionEnum } from '../reducers/user/types';
import { UserActionCreators } from '../reducers/user/action-creators';
import { IErrorResponse, ISuccessResponse } from '../../models/IResponse';
import { IUserGlobal } from '../../models/IUser';
import { DepositCreate, GetUserBalance, GetUserTransaction, WithdrawalCreate } from '../../apis/finances';
import { RootState } from '..';
import { IDeposit, IWithdrawal, TypeOfPayments } from '../../models/IFinances';
import { notification } from 'antd';
import { AppActionCreators } from '../reducers/app/action-creators';

export function* workerFetchUser({ payload }: TFetchUser): Generator {
    const user: ISuccessResponse<IUserGlobal> | IErrorResponse | any = yield FetchUser(payload)

    if (user.status !== ICodes.OK) {
        return;
    }

    yield put(UserActionCreators.updateUser(user.data.data));
}

export function* workerFetchFinances({ payload }: FetchFinances): Generator {
    const finances: ISuccessResponse | IErrorResponse | any = yield GetUserBalance(payload)
    const transactions: ISuccessResponse | IErrorResponse | any = yield GetUserTransaction(payload)

    if (finances.status !== ICodes.OK) {
        return;
    }

    yield put(UserActionCreators.updateFinances({
        ...finances.data.data,
        transactions: transactions.data.data
    }));
    yield put(AppActionCreators.handleLoader(false));
}

export function* workerCreateTransaction({ payload }: CreateTransaction): Generator {
    const user: IUserGlobal | any = yield select((state: RootState) => state.user.user);

    const data: any = Object.fromEntries(Object.entries({ ...payload, type: '' }).filter(([_, v]) => Boolean(v)));

    const sendingData: IWithdrawal | IDeposit = {
        ...data,
        user_id: user.user_id
    }

    let response: ISuccessResponse | IErrorResponse | any = null

    switch (payload.type) {
        case TypeOfPayments.DEPOSIT:
            response = yield DepositCreate(sendingData)
            break;

        case TypeOfPayments.WITHDRAWAL:
            response = yield WithdrawalCreate(sendingData)
            break;


        default:
            break;
    }

    if (response.status !== ICodes.OK) {
        return;
    } else {
        switch (payload.type) {
            case TypeOfPayments.DEPOSIT:
                notification.success({ message: `You have successfully completed the payment, ${payload.amount} $` });
                break;

            case TypeOfPayments.WITHDRAWAL:
                notification.success({ message: `You have successfully withdrawn funds ${payload.amount} $` });
                break;


            default:
                break;
        }
    }

    yield put(UserActionCreators.updateFinances(response.data.data));
}

export function* watchUser() {
    yield takeLatest(UserActionEnum.FETCH_USER, workerFetchUser)
    yield takeLatest(UserActionEnum.FETCH_FINANCES, workerFetchFinances)
    yield takeLatest(UserActionEnum.CREATE_TRANSACTION, workerCreateTransaction)
}