import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import VerticalNav from './vertical-nav'
import Scrollbar from 'smooth-scrollbar'
import Logo from '../logo'
import { DashboardRouteNames } from '../../router/Dashboard'
import logoImg from '../../assets/images/logo.svg';
import logoImgMini from '../../assets/images/logo-mini.svg';

const Sidebar = () => {
    const [showMenu, setShowMenu] = useState(true);

    useEffect(
        () => {
            const scrollBar: HTMLElement | null = document.querySelector('#my-scrollbar')
            if (!scrollBar) return;

            Scrollbar.init(scrollBar)
        }
    )
    const minisidebar = () => {
        setShowMenu(!showMenu)
        document.getElementsByTagName('ASIDE')[0].classList.toggle('sidebar-mini')
    }

    return (
        <>
            <aside className="sidebar sidebar-default navs-rounded-all {{ sidebarVariants }}">
                <div className="sidebar-header d-flex align-items-center justify-content-start">
                    <Link to={DashboardRouteNames.USER_PROFILE} className="navbar-brand">
                        {showMenu ? <Logo src={logoImg} /> : <Logo src={logoImgMini} />}
                    </Link>
                    <div className="sidebar-toggle" data-toggle="sidebar" data-active="true" onClick={minisidebar} >
                        <i className="icon">
                            <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.25 12.2744L19.25 12.2744" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </i>
                    </div>
                </div>
                <div className="sidebar-body pt-0 data-scrollbar" data-scroll="1" id="my-scrollbar">
                    <div className="collapse navbar-collapse" id="sidebar">
                        <VerticalNav />
                    </div>
                </div>
                <div className="sidebar-footer"></div>
            </aside>
        </>
    )
}

export default Sidebar

