import { takeLatest, select } from 'redux-saga/effects'
import { IUserGlobal } from '../../models/IUser';
import { RootState } from '..';
import { SendRequestByService, ServicesActionEnum } from '../reducers/services/types';

export function* workerSendRequest({ payload }: SendRequestByService): Generator {
    const user: IUserGlobal | any = yield select((state: RootState) => state.user.user);

    const sendingData = {
        first_name: user.userInfo.first_name,
        last_name: user.userInfo.last_name,
        phone: user.userInfo.phone,
        mail: user.mail,
        service: payload
      }

    console.log(sendingData);
    
}

export function* watchServices() {
    yield takeLatest(ServicesActionEnum.SEND_REQUEST_BY_SERVICE, workerSendRequest)
}