import React, { useEffect, useState } from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import Card from '../../../components/Card'

import { GetPaymentSystem } from '../../../apis/finances'
import { notificationError } from '../../../plugins/requestNotification'
import { IPaymentSystem, TypeOfPaymentsIds } from '../../../models/IFinances'

const Transactions = () => {
   const [paymentSystems, setPaymentSystems] = useState<IPaymentSystem[]>([]);

   useEffect(() => {
      fetchPaymentSystem()
   }, [])

   const fetchPaymentSystem = async () => {
      try {
         const { data } = await GetPaymentSystem(TypeOfPaymentsIds.DEPOSIT);
         setPaymentSystems(data.data)
      } catch (e) {
         notificationError(e)
      }
   }

   return (
      <Row>
         {paymentSystems.map(({ icon, payment_id }: IPaymentSystem) =>
            <Col key={payment_id} sm="4">
               <Card className="h-100">
                  <Card.Body className="p-10 d-flex justify-content-center">
                     <Image src={icon} />
                  </Card.Body>
               </Card>
            </Col>
         )}
      </Row>
   )
}

export default Transactions;
