import { FC, useState } from "react";
import { FieldInputProps } from "formik";

import hidePasswordIcon from '../../../assets/images/icons/hide_password.svg';
import showPasswordIcon from '../../../assets/images/icons/show_password.svg';

interface PasswordProps {
  field: FieldInputProps<any>;
}

const Password: FC<PasswordProps> = ({
  field, // { name, value, onChange, onBlur }
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="input input--password">
      <input
        {...field}
        className="input__field"
        type={showPassword ? 'text' : 'password'}
      />
      <span className="input__field--password" onClick={handleShowPassword}>
        {!showPassword ?
          <img src={hidePasswordIcon} alt="hide password" />
          : <img src={showPasswordIcon} alt="show password" />
        }
      </span>
    </div>
  )
}


export default Password;