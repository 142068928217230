import { THistory } from "../../../models/IRoute";
import { IUserLogin, IUserRegister, IUserResetPassword } from "../../../models/IUser";
export interface AuthState {
    isAuth: boolean,
    stepOfRegist: number
    totalStep: number
}

export enum AuthActionEnum {
    LOGOUT = "LOGOUT",
    LOGIN = "LOGIN",
    LOGIN_INIT = "LOGIN_INIT",
    LOGIN_2_AUTH = "LOGIN_2_AUTH",
    SET_AUTH = "SET_AUTH",
    REGISTER = "REGISTER",
    RESET_PASSWORD = "RESET_PASSWORD",
    UPDATE_PASSWORD = "UPDATE_PASSWORD",
    SET_STEP_OF_REGIST = "SET_STEP_OF_REGIST",
    SET_TOTAL_STEP = "SET_TOTAL_STEP",
}

export interface LoginInit {
    type: AuthActionEnum.LOGIN_INIT;
}
export interface Logout {
    type: AuthActionEnum.LOGOUT;
}
export interface Login {
    type: AuthActionEnum.LOGIN;
    payload: {
        data: IUserLogin,
        history: THistory,
    };
}
export interface Login2Auth {
    type: AuthActionEnum.LOGIN_2_AUTH;
    payload: boolean,
}
export interface SetStepOfRegist {
    type: AuthActionEnum.SET_STEP_OF_REGIST;
    payload: number;
}
export interface SetTotalStep {
    type: AuthActionEnum.SET_TOTAL_STEP;
    payload: number;
}

export interface SetAuth {
    type: AuthActionEnum.SET_AUTH;
    payload?: boolean;
}
export interface ResetPassword {
    type: AuthActionEnum.RESET_PASSWORD;
    payload: {
        data: { mail: string },
        history: THistory,
    };
}
export interface UpdatePassword {
    type: AuthActionEnum.UPDATE_PASSWORD;
    payload: { data: IUserResetPassword, history: THistory };
}
export interface Register {
    type: AuthActionEnum.REGISTER;
    payload: IUserRegister;
}

export type AuthAction =
    Login |
    SetAuth |
    Register |
    ResetPassword |
    UpdatePassword |
    SetStepOfRegist |
    SetTotalStep