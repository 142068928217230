import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { ICompTransaction, ITransactionDetail, TansactionDetailNames } from "../../models/IUser";

import { useActions } from "../../hooks/useActions";
import { renderDateAndTime } from "../../plugins/helpers";

interface TransactionModalProps {
  transaction: ICompTransaction | null,
  setShowModal: (modal: null | ICompTransaction) => void,
}

const TransactionModal: FC<TransactionModalProps> = ({ transaction, setShowModal }) => {
  const { setRepeatingTransaction } = useActions()

  const handleClose = () => {
    setShowModal(null)
  };

  const handleRepeatTransaction = () => {
    setRepeatingTransaction(transaction)
    handleClose()
  }

  return (
    <Modal className="transaction_modal" show={!!transaction} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Transaction info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {transaction && transaction.detail.map((col: ITransactionDetail) =>
          <div key={col.name} className="row">
            <div className="col-4">
              {col.name}:
              </div>
            <div className="col-8">
              {col.name === TansactionDetailNames.DATE ? renderDateAndTime(Number(col.value)) : col.value}
            </div>
          </div>
        )}
        <div className="row mt-3">
          <div className="col-6">
            <Button
              variant="primary"
              onClick={handleRepeatTransaction}
            >
              Repeat Transaction
              </Button>
          </div>
          <div className="col-6">
            <Button variant="primary">
              Download
              </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TransactionModal;