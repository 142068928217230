import { ITransaction } from "../../../models/IFinances";
import { IUserFinances, IUserGlobal } from "../../../models/IUser";
import * as types from "./types";

export const UserActionCreators = {
    updateUser: (payload: IUserGlobal | null): types.UpdateUser =>
        ({ type: types.UserActionEnum.UPDATE_USER, payload }),
    fetchUser: (payload: number): types.FetchUser =>
        ({ type: types.UserActionEnum.FETCH_USER, payload }),
    fetchFinances: (payload: number): types.FetchFinances =>
        ({ type: types.UserActionEnum.FETCH_FINANCES, payload }),
    updateFinances: (payload: IUserFinances): types.UpdateFinances =>
        ({ type: types.UserActionEnum.UPDATE_FINANCES, payload }),
    createTransaction: (payload: ITransaction): types.CreateTransaction =>
        ({ type: types.UserActionEnum.CREATE_TRANSACTION, payload }),
}
