import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en';
import { ICompTransaction } from "../../models/IUser";

import InfoIcon from '../../assets/images/icons/info-circle.svg';
import TransactionModal from "./TransactionModal";
import { renderDateAndTime } from "../../plugins/helpers";

const pageOptions = [10, 25, 50, 100];
const typeOptions = ['All', 'Deposit', 'Withdrawal', 'Services'];
const statuOptions = ['All', 'Approved', 'Pending', 'Declined'];

const TransactionsTable = () => {
  const { finances, user } = useTypedSelector(state => state.user)

  const [showInformer, setShowInformer] = useState<ICompTransaction | null | any>(null);

  const [perPage, setPerPage] = useState(pageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentType, setCurrentType] = useState(typeOptions[0]);
  const [currentStatus, setCurrentStatus] = useState(statuOptions[0]);
  const [currentDates, setCurrentDates] = useState<Date[]>();

  useEffect(() => {
    new AirDatepicker(`#datePicker`, {
      range: true,
      locale: localeEn,
      onSelect: (datepicker: any) => {
        setCurrentDates(datepicker.datepicker.selectedDates);
      },
    })
  }, [])

  const renderPaginationsInfo = () => {
    const countOrRows = perPage * currentPage;
    const showingPage = countOrRows + 1 - perPage;
    const totalCount = finances?.transactions?.count;

    if (!totalCount) return;

    const relativeOfTotal = countOrRows < totalCount ? countOrRows : totalCount;

    return (
      <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
        Showing {showingPage} to {relativeOfTotal} of {totalCount} entries
      </div>
    )
  }

  const renderStatus = (status: string) => {
    switch (status) {
      case "Approved":
        return <div className="text-success">
          {status}
        </div>

      case "Declined":
        return <div className="text-danger">
          {status}
        </div>

      default:
        return <div className="text-info">
          {status}
        </div>
    }
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [perPage])

  useEffect(() => {
    handleChangePage()
  }, [currentPage, perPage, currentType, currentStatus, currentDates])

  const renderHash = (hash: string) => {
    return hash.slice(0, 10) + '...';
  }

  const renderListOfNumbers = (): number[] => {
    if (!finances?.transactions?.count) return [];

    let countOfPage = Math.ceil(finances?.transactions?.count / perPage);

    const result = [];
    while (countOfPage > 0) {
      result.push(countOfPage--);
    }
    return result.reverse();
  }

  const isDisablingNextBtn = () => {
    if (!finances?.transactions?.count) return false;
    return currentPage * perPage > finances?.transactions?.count
  }

  const handleChangePage = () => {
    if (!user || !currentDates?.length) return;

    let date_start = `${currentDates[0].setHours(0, 0, 0)}`.slice(0, -3);
    let date_end = '';

    if (currentDates?.length === 1) {
      date_end = `${currentDates[0].setHours(23, 59, 59)}`.slice(0, -3)

    } else {
      date_end = `${currentDates[1].setHours(23, 59, 59)}`.slice(0, -3);
    }

    const payload = {
      rows: perPage,
      pagination: currentPage,
      user_id: user.user_id,
      type: currentType,
      status: currentStatus,
      date_start,
      date_end,
    }

    console.log('payload', payload);

    // const count = await this.$store.dispatch(`finances/${LOAD_TRANSACTIONS_DATA}`, { payload })
    // this.transactionsCount = count;
  }

  return (
    <>
      <TransactionModal transaction={showInformer} setShowModal={setShowInformer} />
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Transactions History</h4>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <div className="table-responsive mt-4">
            <div className="row m-0 w-100">
              <div className="col-2">
                Type
              <select
                  className="input__field"
                  onChange={(e) => setCurrentType(e.target.value)}
                >
                  {typeOptions.map(option =>
                    <option key={option} value={option}>{option}</option>
                  )}
                </select>
              </div>
              <div className="col-2">
                Status
              <select
                  className="input__field"
                  onChange={(e) => setCurrentStatus(e.target.value)}
                >
                  {statuOptions.map(option =>
                    <option key={option} value={option}>{option}</option>
                  )}
                </select>
              </div>
              <div className="col-3">
                Date
              <div className="react-datepicker__input-container">
                  <input
                    id="datePicker"
                    readOnly={true}
                    placeholder="All"
                  />
                </div>
              </div>
            </div>
            <table id="basic-table" className="table table-striped mb-0" role="grid">
              <thead>
                <tr className="text-center">
                  <th>Time and date</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Payment ID</th>
                  <th>Fee</th>
                  <th>Amount</th>
                  <th>Service</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {finances?.transactions && finances?.transactions?.data.map(transaction => (
                  <tr key={transaction.id} className="text-center">
                    <td>{renderDateAndTime(Number(transaction.created_at))}</td>
                    <td>{transaction.type}</td>
                    <td>{renderStatus(transaction.status)}</td>
                    <td>{renderHash(transaction.hash)}</td>
                    <td>{transaction.fee}</td>
                    <td>{transaction.amount}</td>
                    <td>-</td>
                    <td>
                      <button
                        className="button--icon"
                        onClick={() => setShowInformer(transaction)}
                      >
                        <img src={InfoIcon} alt="InfoIcon" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card.Body>
        <Card.Footer>
          <div className="row align-items-center">
            <div className="col-md-4">
              {renderPaginationsInfo()}
            </div>
            <div className="col-md-4 d-flex align-items-center">
              Rows per page
            <div className="input">
                <select
                  className="input__field"
                  onChange={(e) => setPerPage(Number(e.target.value))}
                >
                  {pageOptions.map(option =>
                    <option key={option} value={option}>{option}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dataTables_paginate paging_simple_numbers">
                <ul className="pagination mb-0">
                  <li className={`paginate_button page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage - 1)}
                    >
                      Previous
                  </button>
                  </li>
                  {renderListOfNumbers().map(item =>
                    <li
                      className={`paginate_button page-item ${currentPage === item ? 'active' : ''}`}
                      key={item}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(Number(item))}
                      >
                        {item}
                      </button>
                    </li>
                  )}
                  <li className={`paginate_button page-item ${isDisablingNextBtn() ? 'disabled' : ''}`}>
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      Next
                  </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Card.Footer>
      </Card>
    </>
  )
}

export default TransactionsTable;