import { IServise, ILocation, TypeBookNow, IOrder } from "../../../models/IBookNow";

export interface ISelectedService {
  data: IServise | ILocation,
  type: TypeBookNow
}
export interface ServicesState {
  TypeOfServices: IServise[],
  Locations: ILocation[],
  selectedService: null | ISelectedService,
  selectedOrder: null | IOrder,
  orders: IOrder[]
  filters: {
    services: string,
    location: string,
    startDate: any,
    endDate: string,
    participants: string,
  },
}

export enum ServicesActionEnum {
  SELECT_SERVICE = "SELECT_SERVICE",
  SELECT_FILTERS = "SELECT_FILTERS",
  SEND_REQUEST_BY_SERVICE = "SEND_REQUEST_BY_SERVICE",
  SELECT_ORDER = "SELECT_ORDER",
}

export interface SelectService {
  type: ServicesActionEnum.SELECT_SERVICE;
  payload: ISelectedService | null,
}
export interface SelectOrder {
  type: ServicesActionEnum.SELECT_ORDER;
  payload: IOrder | null,
}

export interface SelectFilters {
  type: ServicesActionEnum.SELECT_FILTERS;
  payload: {
    value: any,
    type: string
  },
}

export interface SendRequestByService {
  type: ServicesActionEnum.SEND_REQUEST_BY_SERVICE;
  payload: string,
}

export type ServicesAction = SelectService |
  SelectOrder | SelectFilters