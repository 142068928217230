import { IOrder } from "../../../models/IBookNow";
import * as types from "./types";

export const ServicesActionCreators = {
  selectService: (payload: types.ISelectedService | null): types.SelectService =>
    ({ type: types.ServicesActionEnum.SELECT_SERVICE, payload }),
  selectOrder: (payload: null | IOrder): types.SelectOrder =>
    ({ type: types.ServicesActionEnum.SELECT_ORDER, payload }),
  sendRequestByService: (payload: string): types.SendRequestByService =>
      ({type: types.ServicesActionEnum.SEND_REQUEST_BY_SERVICE, payload}),
  selectFilters: (payload: {
    value: any,
    type: string
  }): types.SelectFilters =>
      ({type: types.ServicesActionEnum.SELECT_FILTERS, payload}),
}
