import {Button, Card, Col, Row} from 'react-bootstrap'
import React, { FC, useEffect, useState } from 'react';
import CardOfOrder from './CardOfOrder';
import { IFieldFront, TypesOfInput } from '../../../models/IField';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { IOrder, TypeBookNow } from '../../../models/IBookNow';
import FieldWithoutValidation from '../../uikit/FieldWithoutValidation ';
import { FieldInputProps } from 'formik';
import {useActions} from "../../../hooks/useActions";

const BookNowSecond: FC = () => {
  const { selectedService, filters, TypeOfServices, Locations, orders } = useTypedSelector(state => state.services)

  const OptionsServices = [{ key: '', value: '' }, ...TypeOfServices.filter(service => !service.disabled).map(service => ({ value: service.name, key: service.name }))]
  const OptionsLocations = [{ key: '', value: '' }, ...Locations.filter(location => !location.disabled).map(location => ({ value: location.name, key: location.name }))]

  const [service, setService] = useState('');
  const [location, setLocation] = useState('');
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const [participants, setParticipants] = useState('1');
  const { selectService, selectFilters } = useActions();

  const ordersForOrders: IOrder[] = orders
    .filter(order => service ? order.name === service : true)
    .filter(order => location ? order.location === location : true)

  useEffect(() => {
    if (selectedService && selectedService.data) {
      if (selectedService.type === TypeBookNow.SERVICES) {
        setService(selectedService.data.name)
      } else if (selectedService.type === TypeBookNow.BUILDINGS) {
        setLocation(selectedService.data.name)
      }
    }
  }, [])

  const Fields: { field: IFieldFront, input: FieldInputProps<any> }[] = [
    {
      input: {
        value: service,
        onChange: (e: React.ChangeEvent<any>) => {
          setService(e.target.value)

          selectFilters({
            value: '',
            type: 'endDate'
          })
        },
        name: 'service',
        onBlur: () => { },
      },
      field: {
        id: 'service',
        size: 2,
        options: OptionsServices,
        type: TypesOfInput.SELECT,
        title: 'Services',
      },
    },
    {
      input: {
        value: location,
        onChange: (e: React.ChangeEvent<any>) => { setLocation(e.target.value) },
        name: 'location',
        onBlur: () => { },
      },
      field: {
        id: 'location',
        size: 4,
        options: OptionsLocations,
        type: TypesOfInput.SELECT,
        title: 'Location',
      },
    },
    {
      input: {
        value: filters.startDate,
        onChange: (e: any) => {
          if (selectedService){
            switch (selectedService.data.id){
              case 2: {
                selectFilters({
                  value: e.getTime() + 86400000 * 29,
                  type: 'endDate'
                })
              }
            }
          }
          setStartDate(e)
          selectFilters({
            value: e.getTime(),
            type: 'startDate'
          })
        },
        name: 'startDate',
        onBlur: () => { },
      },
      field: {
        id: 'startDate',
        size: 2,
        type: TypesOfInput.DATEPICKER,
        title: 'Start day',
        placeholder: 'Choose date',
      },
    },
    {
      input: {
        value: filters.endDate,
        onChange: (e: React.ChangeEvent<any>) => {
          setEndDate(e)
          selectFilters({
            value: e,
            type: 'endDate'
          })},
        name: 'endDate',
        onBlur: () => { },
      },
      field: {
        id: 'endDate',
        size: 2,
        type: TypesOfInput.DATEPICKER,
        title: 'End date',
        placeholder: 'Choose date',
        value: filters.endDate,
      },
    },
    {
      input: {
        value: participants,
        onChange: (e: React.ChangeEvent<any>) => { setParticipants(e.target.value) },
        name: 'participants',
        onBlur: () => { },
      },
      field: {
        id: 'participants',
        size: 2,
        type: TypesOfInput.TEXT,
        title: 'Participants',
        placeholder: 'Quantity',
        disabled: true,
      },
    },
  ]

  return (
      <>
        <Button
            type="submit"
            variant="outline-primary"
            className="payment-block__btn--back mb-4"
            onClick={() => selectService(null)}
        >
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3681 8.12891H5.2259L10.6978 3.37891C10.7853 3.30234 10.7321 3.16016 10.6165 3.16016H9.23371C9.17277 3.16016 9.11496 3.18203 9.06965 3.22109L3.16496 8.34453C3.11088 8.39141 3.06751 8.44936 3.03778 8.51447C3.00806 8.57957 2.99268 8.65031 2.99268 8.72188C2.99268 8.79344 3.00806 8.86418 3.03778 8.92928C3.06751 8.99439 3.11088 9.05234 3.16496 9.09922L9.10402 14.2539C9.12746 14.2742 9.15559 14.2852 9.18527 14.2852H10.615C10.7306 14.2852 10.7837 14.1414 10.6962 14.0664L5.2259 9.31641H14.3681C14.4368 9.31641 14.4931 9.26016 14.4931 9.19141V8.25391C14.4931 8.18516 14.4368 8.12891 14.3681 8.12891Z" fill="#3A57E8" />
          </svg>
          Back
        </Button>
        <Row>
          <Col lg="12">
            <Card>
              <Card.Body>
                <Row>
                  {Fields.map(field =>
                      <FieldWithoutValidation className="filters__delimiter" service={service} key={field.input.name} {...field} />
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
          {ordersForOrders.map(order => <CardOfOrder key={order.id} order={order} />)}
        </Row>
      </>

  )
}

export default BookNowSecond;