import MaskedInput from "react-text-mask";

const expirationDate = [/[0-9]/, /[0-9]/, "/", /[0-9]/, /[0-9]/];

const ExpirationDate = ({ field }: any) => {
  return (
    <MaskedInput
      mask={expirationDate}
      guide={true}
      className="input__field"
      placeholderChar="_"
      showMask
      {...field}
    />
  );
};

export default ExpirationDate;
