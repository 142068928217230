export interface IUser2Auth {
    has_2fa?: boolean
    user_id: number,
    hash: string,
    code: string,
}

export interface IUserAuth {
    auth_token: string,
    refresh_token: string,
}
export interface IUserLogin {
    mail: string,
    password: string,
    remember?: boolean,
}
export interface IUserShortFields {
    mail: string,
    password: string,
    confirm_password: string,
    agreement_confirm: boolean | number,
    mail_code?: string,
}
export interface IUserFullFields {
    first_name: string,
    last_name: string,
}
export interface IUserRegister {
    step: number,
    role_id: number,
    user_id?: number,
    fields: IUserShortFields | IUserFullFields,
}

export interface IUserResetPassword {
    token?: string,
    password: string,
    confirm_password: string,
}
export interface IUserChangePassword {
    old_password: string,
    password: string,
    confirm_password: string,
    user_id?: number,
}
export interface IUserInfo {
    user_id: number,
    first_name: string,
    last_name: string,
    middle_name: null
    username: null
    photo: null
    phone_code: string,
    phone: string,
    birth_date: string,
    shipping_country: null
    shipping_city: null
    shipping_address: null
    shipping_state: null
    shipping_zip_code: null
    billing_country: null
    billing_city: null
    billing_address: null
    billing_state: null
    billing_zip_code: null
    verification_photo: string,
    identification_type: string,
    expiration_date: string,
    net_worth: null
    annual_income: null
    decline_text: null
}

export interface IUserGlobal {
    has_2fa?: boolean,
    user_id: number,
    mail: string,
    status: string,
    hash?: string,
    role: {
        id: number,
        name: string,
        time: string,
    }
    two_factor_auth: number,
    two_factor_auth_method: number
    risk_confirm: string,
    mail_verification: string,
    phone_verification: string,
    created_at: string,
    userInfo: IUserInfo
    userStatus: {
        id: number,
        name: string,
        description: string,
    }
}

export enum TansactionDetailNames {
    TYPE = 'Type',
    AMOUNT = 'Amount',
    FEE = 'Fee',
    DATE = 'Date and Time',
    STATUS = 'Status',
    ID = 'Payment ID',
}
export interface ITransactionDetail {
    value: string | number,
    name: TansactionDetailNames,
}
export interface ICompTransaction {
    amount: number
    created_at: string
    created_at_human: string
    currency: string
    decline_text: string | null
    description: string
    fee: number
    fee_percentage: number
    hash: string
    id: string
    invoice_number: string
    payment_system: number
    payment_system_name: string
    status: string
    status_id: string
    sum: number
    type: string
    user_id: string
    user_mail: string
    detail: ITransactionDetail[]
}

export interface IUserFinances {
    balance: null | number,
    transactions: null | {
        data: ICompTransaction[],
        count: number,
    }
}