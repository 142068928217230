import { IFieldFront, TypesOfInput } from "./models/IField";
import { TypeOfPayments } from "./models/IFinances";

const RegexpStrMastercard =
  "^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$";
const RegexpStrVisa = "^4[0-9]{12}(?:[0-9]{3})?$";
const RegexpStrAmex = "^3[47][0-9]{13}$";
const RegexpStrMaestro = "^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$";
const cardRegexp = new RegExp(
  `${RegexpStrMastercard}|${RegexpStrVisa}|${RegexpStrAmex}|${RegexpStrMaestro}`,
  "i"
);

export const Regulars = {
  password: {
    reg: /^(?=.*[0-9])(?=.*[A-Z])[0-9a-zA-Z!@#:.\-"`/|\\(){}[\]=+,<>~;'_$%^&*?]{8,}$/,
    text: "At least 8 characters, at least one upercase, at least one digit",
  },
  email: {
    reg: /^(?!^.{65})(([^<>()[\]\\.+\-,#%^&`?/()~<>={}|*$!;:\s@"]+(\.[^<> ()[\]\\.,#%^&`?/()~<>={}|*$!;:\s@"]+)*)|(".+"))@((\[[0-9 ]{1,3}\.[0-9 ]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    text: "Email is invalid",
  },
  cardholder: {
    reg: /^(?!^.{49})^[a-zA-Z]+(([' -][a-zA-Z ])?[a-zA-Z]*)*$/,
    text: "Cardholder is invalid",
  },
  cardNumber: {
    reg: cardRegexp,
    text: "Card is invalid",
  },
  cvv: {
    reg: /^[0-9]{3,4}$/,
    text: "CVV is invalid",
  },
  expirationDate: {
    reg: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
    text: "Date is invalid",
  },
};

export const messages = {
  SHOW_MODAL_TITLE_EMAIL: "We sent a code to your email address.",
  SHOW_MODAL_DES_EMAIL:
    "The code we sent you to your email is for you to confirm your email",
  SHOW_MODAL_TITLE_2FA:
    "The login code was sent to you on the connected two-factor authentication",
  SUCCESS_LOGIN: "You have successfully logged into the Members Area.",
  SUCCESS_REGISTER: "You have successfully registered in the cabinet",
  SUCCESS_PASSWORD_CHANGE: "You have successfully changed your password",
  SUCCESS_PASSWORD_RESET_TITLE: "Password Reset",
  SUCCESS_PASSWORD_RESET:
    "If this email address was used to create an account, instructions to reset your password will be sent to you. Please check your email.",
  LOGIN_VALIDATION_ERROR:
    "The account login was incorrect or wrong password has been typed. Please try again.",
  GOOGLE_2FA: "Google authenticator is still in development",
  CHANGE_2FA_TITLE:
    "We sent you the code to connect two-factor authorization by email",
  CHANGE_2FA_DES:
    "When you enter the code, you will be able to enter the cabinet only with the code that we will send you to the email that you entered during registration",
  REMOVE_SESSIONS: "You only have one active session",
  REMOVE_SESSIONS_SUCCESS:
    "All active sessions have been crossed except the current one",
  MEETING_ROOMS:
    "MEETING ROOMS service is available in all locations: VIB - BRATTLEBORO, VIB2 - DOWNTOWN BRATTLEBORO, VIB3 - DOWNTOWN BENNINGTON. This service is only available for long-term rentals. To arrange the Meeting rooms service, please fill out the form and send us your details.",
  PRIVATE_OFFICE:
    "PRIVATE OFFICE service is available in all locations: VIB - BRATTLEBORO, VIB2 - DOWNTOWN BRATTLEBORO, VIB3 - DOWNTOWN BENNINGTON. This service is only available for long-term rentals. To arrange the Private office service, please fill out the form and send us your details.",
  VIB2: "In location VIB2 - DOWNTOWN BRATTLEBORO only Private Office service is available. This service is available only for long-term rentals. To arrange the service Private Office click on the button Send Request and we will contact you.",
};

export const LocalObject = {
  deposit_fee: 3,
  deposit_max: 1000000,
  deposit_min: 10,
  payment_id: 4,
  payment_name: "Local",
  withdrawal_fee: 3,
  withdrawal_max: 1000000,
  withdrawal_min: 10,
};

export const FinancesFields: {
  [key: string]: { [key: number]: IFieldFront[] };
} = {
  // Deposit
  [TypeOfPayments.DEPOSIT]: {
    // Pay Pal
    1: [
      {
        id: "wallet",
        required: true,
        size: 8,
        type: TypesOfInput.TEXT,
        category_id: 1,
        placeholder: "some.example@email.com",
        title: "PayPal Email",
        validation: `${Regulars.email.reg}`,
        validation_message: Regulars.email.text,
      },
    ],
    // Autorise
    2: [
      {
        id: "card_number",
        required: true,
        size: 8,
        type: TypesOfInput.TEXT,
        category_id: 1,
        placeholder: "XXXX XXXX XXXX XXXX",
        title: "Card Number",
        validation: `${Regulars.cardNumber.reg}`,
        validation_message: Regulars.cardNumber.text,
      },
      {
        id: "cvv",
        required: true,
        size: 4,
        type: TypesOfInput.PASSWORD,
        category_id: 1,
        placeholder: "666",
        title: "CVV",
        validation: `${Regulars.cvv.reg}`,
        validation_message: Regulars.cvv.text,
      },
      {
        id: "expiration_date",
        required: true,
        size: 4,
        type: TypesOfInput.TEXT,
        category_id: 1,
        placeholder: "12/12",
        title: "Expiration Date",
        validation: `${Regulars.expirationDate.reg}`,
        validation_message: Regulars.expirationDate.text,
      },
    ],
    // Stripe
    3: [
      {
        id: "card_number",
        required: true,
        size: 8,
        type: TypesOfInput.TEXT,
        category_id: 1,
        placeholder: "XXXX XXXX XXXX XXXX",
        title: "Card Number",
        validation: `${Regulars.cardNumber.reg}`,
        validation_message: Regulars.cardNumber.text,
      },
      {
        id: "cvv",
        required: true,
        size: 4,
        type: TypesOfInput.PASSWORD,
        category_id: 1,
        placeholder: "666",
        title: "CVV",
        validation: `${Regulars.cvv.reg}`,
        validation_message: Regulars.cvv.text,
      },
      {
        id: "expiration_date",
        required: true,
        size: 4,
        type: TypesOfInput.TEXT,
        category_id: 1,
        placeholder: "12/12",
        title: "Expiration Date",
        validation: `${Regulars.expirationDate.reg}`,
        validation_message: Regulars.expirationDate.text,
      },
    ],
  },

  // Withdraw
  [TypeOfPayments.WITHDRAWAL]: {
    // Pay Pal
    1: [
      {
        id: "wallet",
        required: true,
        size: 8,
        type: TypesOfInput.TEXT,
        category_id: 1,
        placeholder: "some.example@email.com",
        title: "PayPal Email",
        validation: `${Regulars.email.reg}`,
        validation_message: Regulars.email.text,
      },
    ],
    // Bank
    5: [
      {
        id: "wallet",
        required: true,
        size: 8,
        type: TypesOfInput.TEXT,
        category_id: 1,
        placeholder: "XXXX XXXX XXXX XXXX",
        title: "Card Number",
        validation: `${Regulars.cardNumber.reg}`,
        validation_message: Regulars.cardNumber.text,
      },
      {
        id: "card_holder",
        required: true,
        size: 8,
        type: TypesOfInput.TEXT,
        category_id: 1,
        placeholder: "Enter Cardholder Name",
        title: "Cardholder",
        validation: `${Regulars.cardholder.reg}`,
        validation_message: Regulars.cardholder.text,
      },
    ],
  },
};

export const PaymentModalFields = [
  {
    id: "card_number",
    required: true,
    size: 8,
    type: TypesOfInput.CARD,
    category_id: 1,
    placeholder: "XXXX XXXX XXXX XXXX",
    title: "Card Number",
    validation: `${Regulars.cardNumber.reg}`,
    validation_message: Regulars.cardNumber.text,
  },
  {
    id: "expiration_date",
    required: true,
    size: 4,
    type: TypesOfInput.EXP_DATE,
    category_id: 1,
    placeholder: "12/12",
    title: "Expiration Date",
    validation: `${Regulars.expirationDate.reg}`,
    validation_message: Regulars.expirationDate.text,
  },
  {
    id: "cvv",
    required: true,
    size: 4,
    type: TypesOfInput.CVV,
    category_id: 1,
    placeholder: "666",
    title: "CVV",
    validation: `${Regulars.cvv.reg}`,
    validation_message: Regulars.cvv.text,
  },
  {
    id: "card_holder",
    required: true,
    size: 8,
    type: TypesOfInput.TEXT,
    category_id: 1,
    placeholder: "Enter Cardholder Name",
    title: "Cardholder",
    validation: `${Regulars.cardholder.reg}`,
    validation_message: Regulars.cardholder.text,
  },
];
