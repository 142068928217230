import { FC } from "react";
import { FieldInputProps } from "formik";
import { IField } from "../../../models/IField";

interface SelectProps {
  field: FieldInputProps<any>;
  input: IField,
}

const Select: FC<SelectProps> = ({
  input,
  field, // { name, value, onChange, onBlur }
}) => {

  return (
    <div className="input">
      <select
        {...field}
        className="input__field"
      >
        {input.options && input.options.map(option =>
          <option key={option.key} value={option.key}>{option.value}</option>
        )}
      </select>
    </div>
  )
}


export default Select;