import React, { useEffect } from 'react'

//header
import Header from '../components/dashboard/header'
//footer
import Footer from '../components/dashboard/footer'
//default 
import RouterExtra from '../router/Extra'

const Dashboard = () => {
    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0), 100)
    }, [])

    return (
        <>
            <main className="main-content">
                <div className="position-relative">
                    <Header />
                </div>
                <div className="conatiner-fluid content-inner mt-n5 py-0">
                    <RouterExtra />
                </div>
                <Footer />
            </main>
        </>
    )
}

export default Dashboard;
