import { Modal, Button, Form } from 'react-bootstrap'
import React, { useRef, useState } from 'react';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { handleDisableButton } from '../../plugins/helpers';

const AuthModal = () => {
  const { handleHideModal } = useActions()
  const { isShowModal, modalTitle, modalDescription, modalImage } = useTypedSelector(state => state.app)
  const [text, setText] = useState('');
  const modalButtonRef = useRef(null);

  const handleClose = () => {
    handleHideModal('')
    setText('')
  };

  const handleModal = () => {
    handleDisableButton(modalButtonRef)
    handleHideModal(text)
    setText('')
  }
  
  return (
    <Modal show={isShowModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control className="mb-4" onChange={(e) => setText(e.target.value)} value={text} />
        {modalImage && <img className="auth-modal__img" src={modalImage} alt="google qr" />}
        <p>{modalDescription}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleModal}
          ref={modalButtonRef}
        >
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AuthModal;