import { notification } from 'antd';
import { AxiosError } from 'axios';

export const notificationError = (e: AxiosError | any) => {
    if (!e) return;

    const title = e?.response?.statusText;
    const message = e?.response?.data.message;

    let description = e?.response?.data?.data;
    if (description) {
        description = Object.entries(description);
        description = description?.map((field: string[]) => `${field[0]}: ${field[1]}`)
        description = description?.join('.')
    }

    notification.error({
        message: message || title ||'Error',
        description: description || '',
    });
}