import { FieldInputProps, useFormikContext } from 'formik';
import { useState, FC, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { PostImage } from '../../../apis/fields';
import { ICodes } from '../../../models/ICodes';
import { IFieldFront } from '../../../models/IField';

import pdfIcon from '../../../assets/images/icons/pdf.svg';

interface FileDropProps {
  field: FieldInputProps<any>,
  input: IFieldFront,
}

const MaxFileMb = 5;
const MaxFileSize = MaxFileMb * 1000000;

const FileDrop: FC<FileDropProps> = ({ field, input }) => {
  const [imgUrl, setImgUrl] = useState<string>('')
  const { setFieldValue, setFieldError, setFieldTouched } = useFormikContext() ?? {};

  useEffect(() => {
    if (input.value && typeof input.value === 'string') {
      setImgUrl(input.value)
    }
  }, [input.value])

  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles[0].size > MaxFileSize) {
      setFieldError(field.name, `File must not be bigger than ${MaxFileMb} Mb`)
      setFieldTouched(field.name, true, false)
      return;
    }
    
    const res = await PostImage(field.name, acceptedFiles[0])

    if (res.status === ICodes.VALIDATION_ERROR && res.data.data) {
      const error: string = res.data.data[field.name].join(', ');
      setFieldError(field.name, error)
      setFieldTouched(field.name, true, false)
      return;
    }

    const ulr: string[] = Object.values(res)

    setImgUrl(ulr[0])
    setFieldValue(field.name, ulr[0]);
  }

  const renderFilePreview = () => {
    if (imgUrl.endsWith('.pdf')) {
      return <div className="file-drop-input__preview">
        <img src={pdfIcon} alt="dropped" />
        <a target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()} href={imgUrl}>Preview</a>
      </div>
    }
    return <img src={imgUrl} alt="dropped" />
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()} className="file-drop-input">
      <input {...getInputProps()} accept={input.accept} />
      {imgUrl
        ? renderFilePreview()
        : isDragActive
          ? <p className="file-drop-input__text--active">
            Let's insert your file into me...
            </p>
          : <p className="file-drop-input__text">
            Add your photo
              <span className="file-drop-input__plus">+</span>
          </p>
      }
    </div>
  )
}

export default FileDrop;