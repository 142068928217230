import { FieldInputProps } from "formik";
import React, { FC } from "react";
import { Col, Form } from "react-bootstrap";
import { IFieldFront, TypesOfInput } from "../../models/IField";
import CustomInput from "./inputs/Custom";
import DatePickerNotFormik from "./inputs/DatePickerNotFormik";
import SelectInput from "./inputs/Select";

interface FieldWithoutValidationProps {
  field: IFieldFront,
  input: FieldInputProps<any>,
  className: string,
  service: string
}

const FieldWithoutValidation: FC<FieldWithoutValidationProps> = ({
  field,
  input,
  className,
    service
}: FieldWithoutValidationProps) => {
  const renderInput = () => {
    const props = {
      field: input,
      input: field
    }
    switch (field.type) {
      case TypesOfInput.DATEPICKER:
        return <DatePickerNotFormik {...props} />;

      case TypesOfInput.SELECT:
        return <SelectInput {...props} />;

      default:
        return <CustomInput {...props} />;
    }
  };

  return (
    <Col lg={field.size || '4'} className={className}>
      <Form.Group className={`
          form-group 
          ${field.type === TypesOfInput.CHECKBOX ? 'field-with-validation__checkbox' : ''}
          ${service === "Nomad" ? "nomad" : ""}
        `}>
        <div className="field-with-validation__block">
          {field.title && <Form.Label htmlFor={field.id}>{field.title}</Form.Label>}
          {renderInput()}
        </div>
      </Form.Group>
    </Col>
  )
}

const memoFieldWithoutValidation = React.memo(FieldWithoutValidation);

export default memoFieldWithoutValidation;