import { Card } from 'react-bootstrap'
import React, { FC } from 'react';
import { IOrder } from '../../../models/IBookNow';
import locationImage from '../../../assets/images/location.png';

// icons
import peopleIcon from '../../../assets/images/icons/team.svg'
import calendarIcon from '../../../assets/images/icons/calendar.svg'
import environmentIcon from '../../../assets/images/icons/environment.svg'
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {renderDateAndYear} from "../../../plugins/helpers";

interface CheckoutProps {
  order: IOrder,
}

const Checkout: FC<CheckoutProps> = ({ order }) => {
    const { filters } = useTypedSelector(state => state.services)

    return (
    <>
      <img className="checkout__img" src={locationImage} alt="location" />
      <div className="checkout__main_info">
        <Card.Title as="h2" className="checkout__name">
          {order.name}
        </Card.Title>
        <div className="checkout__price">
          <span>${order.price}</span>
        </div>
      </div>

      <div className="checkout__locations">
        <img className="checkout__icon" src={environmentIcon} alt="environment" />
        {order.location} • {order.available}
      </div>
      <div className="checkout__extrainfo">
        <div>
          <img className="checkout__icon" src={peopleIcon} alt="people" />
          <span className="checkout__info">{order.participants}</span>
        </div>
        <div>
          <img className="checkout__icon" src={calendarIcon} alt="calendar" />
          <span className="checkout__info">{renderDateAndYear(filters.startDate/1000)}</span>
        </div>
      </div>
      <p className="checkout__description">
        {order.description}
      </p>
    </>
  )
}

export default Checkout;