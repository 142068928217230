import { Card, Col, Row } from "react-bootstrap";
import { FC, useState } from "react";
import { IOrder } from "../../../models/IBookNow";

// icons
import PaymentBlock from "./PaymentBlock";
import { notification } from "antd";
import CheckoutOrder from "./CheckoutOrder";
import SuccessfullyPaidBlock from "./SuccessfullyPaidBlock";

interface CheckoutProps {
  order: IOrder;
}

const Checkout: FC<CheckoutProps> = ({ order }) => {
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const handleSubmit = () => {
    notification.success({
      message: "Payment was successful",
    });
    setPaymentSuccess(true);
  };

  return (
    <Row>
      <Col lg="7">
        <Card className="rounded-3 shadow-sm">
          <Card.Body>
            <CheckoutOrder order={order} />
          </Card.Body>
        </Card>
      </Col>
      <Col lg="5">
        {paymentSuccess ? (
          <SuccessfullyPaidBlock />
        ) : (
          <PaymentBlock submit={handleSubmit} />
        )}
      </Col>
    </Row>
  );
};

export default Checkout;
