export enum TypeBookNow {
    SERVICES = 'Services',
    BUILDINGS = 'Buildings',
}

export interface IServise {
    name: string,
    description: string,
    participants: string,
    people: number,
    timetable: string,
    pricePeriod: string,
    locations: number[],
    locationsName?: string[],
    price: string,
    color: string,
    features1: boolean,
    features2: boolean,
    features3: boolean,
    id: number,
    disabled?: boolean,
}

export interface ILocation {
    name: string,
    id: number,
    description: string,
    participants: string,
    services: string[],
    parking: boolean,
    kitchen: boolean,
    outdoor: boolean,
    disabled?: boolean,
}
export interface IOrder {
    name: string,
    location: string,
    timetable: string,
    description: string,
    participants: string,
    available: string,
    price: string,
    id: number,
}