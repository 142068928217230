import React, { FC, useEffect, useRef, useState } from 'react'

import { Row, Col, Image, Tab, Button, Card } from 'react-bootstrap'
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import avatar1 from '../../assets/images/avatars/01.png'
import { FetchProfileFields } from '../../apis/profile';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { ICategoryField } from '../../models/IField';
import { IUserInfo } from '../../models/IUser';
import { handleDisableButton } from '../../plugins/helpers';
import FieldBlock from '../uikit/FieldBlock';

interface ProfileViewProps {
  typeOfPage: number,
}

const ProfileView: FC<ProfileViewProps> = ({ typeOfPage }) => {
  const { user } = useTypedSelector(state => state.user)
  const { errors } = useTypedSelector(state => state.app)
  const { updateProfile, handleLoader } = useActions();
  const formButtonRef = useRef(null);
  const [formikActions, setFormicActions] = useState<any>(null);

  const [validation, setValidation] = useState<any>(null);
  const [fieldValues, setFieldValues] = useState<any>(null);
  const [sortedFieldsByCategory, setSortedFieldsByCategory] = useState<any>(null);

  useEffect(() => {
    if (formikActions && errors && Object.values(errors).length) {
      formikActions.setErrors(errors)
    }
  }, [errors]);

  useEffect(() => {
    handleLoader(true);
    fetchFields()
  }, [user])

  const fetchFields = async () => {
    if (!user) return;
    const response = await FetchProfileFields(user.user_id, typeOfPage);

    setValidation(response.validations)
    setFieldValues(response.initialValues)
    setSortedFieldsByCategory(response.sortedFieldsByCategory)
    handleLoader(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [fieldValues])

  const schema = Yup.object().shape(validation || {});

  const handleSave = async (values: IUserInfo, form: any) => {
    setFormicActions(form);
    handleDisableButton(formButtonRef)
    updateProfile(values);
  }

  return (
    <>
      <Tab.Container defaultActiveKey="first">
        <Row>
          <Col lg="12">
            <Card>
              <Card.Body>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="profile-img position-relative me-3 mb-3 mb-lg-0">
                      <Image src={user?.userInfo?.photo || avatar1} className="img-fluid rounded-pill avatar avatar-100" alt="profile-image" />
                    </div>
                    <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                      <h4 className="me-2 h4">{user?.userInfo?.first_name} {user?.userInfo?.last_name}</h4>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="12">
            <Card>
              <Card.Body>
                <div>
                  {fieldValues && <Formik
                    initialValues={fieldValues}
                    validationSchema={schema}
                    onSubmit={handleSave}
                  >
                    {({ isValid }) => (
                      <Form>
                        <Row>
                          {sortedFieldsByCategory && sortedFieldsByCategory.map((category: ICategoryField) =>
                            <FieldBlock key={category.id} category={category} />
                          )}
                        </Row>
                        <div className="d-flex justify-content-center">
                          <Button
                            type="submit"
                            variant="primary"
                            ref={formButtonRef}
                            disabled={!isValid}
                          >
                            Save
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  }
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Tab.Container>
    </>
  )

}

export default ProfileView;