import { Button, Card, Col } from 'react-bootstrap'
import React, { FC } from 'react';
import { ILocation, IServise, TypeBookNow } from '../../../models/IBookNow';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { notification } from 'antd';
import { messages } from '../../../constants';

// icons
import peopleIcon from '../../../assets/images/icons/team.svg'
import calendarIcon from '../../../assets/images/icons/calendar.svg'
import environmentIcon from '../../../assets/images/icons/environment.svg'

interface CardOfServiceProps {
  service: IServise,
}

const CardOfService: FC<CardOfServiceProps> = ({ service }) => {
  const { Locations } = useTypedSelector(state => state.services)
  const { selectService, sendRequestByService } = useActions()

  const listOfLocations = Locations
    .filter((location: ILocation) => service.locations.includes(location.id))
    .map(location => location.name);

  const handleSelect = () => {
    const key = `open${Date.now()}`;

    const handleRequest = () => {
      sendRequestByService(service.name);
      notification.close(key)
    }

    const btn = (
      <Button variant="primary" onClick={handleRequest}>
        Send request
      </Button>
    );

    switch (service.id) {
      case 3:
        notification.info({
          message: messages.MEETING_ROOMS,
          btn,
          key
        })
        break;

      case 4:
        notification.info({
          message: messages.PRIVATE_OFFICE,
          btn,
          key
        })
        break;

      default:
        selectService({
          data: service,
          type: TypeBookNow.SERVICES
        })
        break;
    }
  }

  return (
    <Col>
      <Card className="mb-4 rounded-3 shadow-sm h100">
        <Card.Body style={{ border: `1px solid ${service.color}` }} className="card_of_service">
          <div>
            <Card.Title as="h2" className="card_of_service__name">{service.name}</Card.Title>
            <p className="card_of_service__description">{service.description}</p>
          </div>
          <div className="card_of_service__info_block">
            <div>
              <img src={peopleIcon} alt="people" />
              <span className="card_of_service__info">{service.participants}</span>
            </div>
            <div>
              <img src={calendarIcon} alt="calendarIcon" />
              <span className="card_of_service__info">{service.timetable}</span>
            </div>
            <div className="card_of_service__locations">
              <img src={environmentIcon} alt="environment" />
              <div className="card_of_service__info">
                {listOfLocations.map(location => <div key={location}>{location}</div>)}
              </div>
            </div>
          </div>
          <div>
            <p className="h2 pricing-card-title">
              <small className="text-muted fw-light">for </small>
              <span className="card_of_service__price">${service.price}</span>
              <small className="text-muted fw-light"> /{service.pricePeriod}</small>
            </p>
            <Button
              style={{
                color: '#000',
                backgroundColor: service.color,
                borderColor: service.color,
                boxShadow: 'none'
              }}
              onClick={handleSelect}
            >
              Book Now
          </Button>
          </div>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default CardOfService;