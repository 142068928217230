import { Button, Card } from "react-bootstrap";
import { FC } from "react";
import { useActions } from "../../../hooks/useActions";

const SuccessfullyPaidBlock: FC = () => {
  const { selectOrder } = useActions();

  return (
    <Card className="rounded-3 shadow-sm">
      <Card.Body>
        <Card.Title as="h2" className="payment-block__name">
          You have successfully paid
        </Card.Title>
        <div className="payment-block__services">
          We've received your payment. We look forward to seeing you in our
          buildings.
        </div>
        <div className="payment-block__buttons">
          <Button
            type="button"
            variant="primary"
            onClick={() => selectOrder(null)}
          >
            Okay
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SuccessfullyPaidBlock;
