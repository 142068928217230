import MaskedInput from "react-text-mask";

const cardNumberMask = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

const CardNumber = ({ field }: any) => {
  return (
    <MaskedInput
      mask={cardNumberMask}
      guide={true}
      className="input__field"
      placeholderChar="_"
      showMask
      {...field}
    />
  );
};

export default CardNumber;
