import * as types from './types';

const initialState: types.ServicesState = {
  TypeOfServices: [
    {
      name: 'Day Passes',
      description: 'An on-demand day pass reservation service',
      participants: 'max per person',
      people: 1,
      timetable: 'Daily/ Month',
      locations: [1, 3],
      price: '25',
      pricePeriod: 'day',
      color: 'rgb(170, 204, 153)',
      features1: false,
      features2: true,
      features3: false,
      id: 1,
    },
    {
      name: 'Nomad',
      description: 'Our expansive cooperative space at your service.',
      participants: 'max per person',
      people: 1,
      timetable: 'Month',
      locations: [1, 3],
      price: '150',
      pricePeriod: 'month',
      color: 'rgb(255, 162, 162)',
      features1: true,
      features2: true,
      features3: false,
      id: 2,
    },
    {
      name: 'Meeting Rooms',
      description: 'Nicely sized and smartly designed conference rooms.',
      participants: 'max 15 person',
      people: 15,
      timetable: 'Month',
      locations: [1, 3],
      price: '200',
      pricePeriod: 'month',
      color: 'rgb(133, 244, 250)',
      features1: true,
      features2: true,
      features3: true,
      id: 3,
      disabled: true,

    },
    {
      name: 'Private Office',
      description: 'We start with a blank canvas and you make it your own.',
      participants: 'max 1 - 4 person',
      people: 4,
      timetable: 'Month',
      locations: [1, 2, 3],
      price: '275',
      pricePeriod: 'month',
      color: 'rgb(186, 196, 247)',
      features1: true,
      features2: true,
      features3: true,
      id: 4,
      disabled: true,

    },
  ],
  Locations: [
    {
      name: 'VIB - Brattleboro',
      id: 1,
      description: 'Old mansion turned coworking space and private offices set back on 16 acres of beautiful grounds located just outside of downtown',
      participants: 'total 500 person',
      services: ['Day Pass', 'Nomad', 'Meeting Rooms - 2', 'Private Offices - 2'],
      parking: true,
      kitchen: false,
      outdoor: false,
    },
    {
      name: 'VIB2 - Downtown Brattleboro',
      id: 2,
      description: 'Private offices located in the midst of Downtown Brattleboro in the historic C.F. Church Building',
      participants: 'total 150 person',
      services: ['Private Offices - 3'],
      parking: true,
      kitchen: true,
      outdoor: true,
      disabled: true,
    },
    {
      name: 'VIB3 - Downtown Bennington',
      id: 3,
      description: 'Coworking space and private offices located in the heart of Downtown Bennington',
      participants: 'total 350 person',
      services: ['Meeting Room - 2', 'Private Offices - 3', 'Day Pass', 'Nomad'],
      parking: true,
      kitchen: true,
      outdoor: true,
    }
  ],
  selectedService: null,
  selectedOrder: null,
  orders: [
    {
      name: 'Nomad',
      location: 'VIB - Brattleboro',
      timetable: 'Monthly',
      description: "Maybe you are a wanderer and do not actually want a dedicated office space. Perhaps you prefer to be around other people in our expansive cooperative space upstairs, working, lounging, chatting it up with others. However, you work best is how we work best and together we'll make it possible. Just grab a seat anywhere in the building, inside or out, and let the creativity flow!",
      participants: 'per person',
      available: 'Available at 9am',
      price: '150',
      id: 1,
    },
    {
      name: 'Nomad',
      location: 'VIB3 - Downtown Bennington',
      timetable: 'Monthly',
      description: "Maybe you are a wanderer and do not actually want a dedicated office space. Perhaps you prefer to be around other people in our expansive cooperative space upstairs, working, lounging, chatting it up with others. However, you work best is how we work best and together we'll make it possible. Just grab a seat anywhere in the building, inside or out, and let the creativity flow!",
      participants: 'per person',
      available: 'Available at 9am',
      price: '150',
      id: 2,
    },
    {
      name: 'Day Passes',
      location: 'VIB - Brattleboro',
      timetable: 'Daily',
      description: "There is open co-working seating in all our open co-working space.",
      participants: 'per person',
      available: 'Available at 9am',
      price: '25',
      id: 3,
    },
    {
      name: 'Day Passes',
      location: 'VIB3 - Downtown Bennington',
      timetable: 'Daily',
      description: "There is open co-working seating in all our open co-working space.",
      participants: 'per person',
      available: 'Available at 9am',
      price: '25',
      id: 4,
    },
  ],
  filters: {
    services: "",
    location: "",
    startDate: "",
    endDate: "",
    participants: '1'
  },
}

export const servicesReducer = (state = initialState, action: types.ServicesAction): types.ServicesState => {
  switch (action.type) {
    case types.ServicesActionEnum.SELECT_SERVICE:
      return {...state, selectedService: action.payload}

    case types.ServicesActionEnum.SELECT_ORDER:
      return {...state, selectedOrder: action.payload}

    case types.ServicesActionEnum.SELECT_FILTERS:
      return {
        ...state, filters: {
          ...state.filters,
          [action.payload.type]: action.payload.value
        }
      }

    default:
      return state;
  }
}

export default servicesReducer;
