import { Row, Col, Image, Form, Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Card from '../../../components/Card'

// img
import auth1 from '../../../assets/images/auth/01.png'
import { useActions } from '../../../hooks/useActions'
import { AuthRouteNames } from '../../../router/Auth'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { Formik, Form as FormicForm } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { IUserLogin } from '../../../models/IUser'
import FieldWithValidation from "../../../components/uikit/FieldWithValidation";
import { TypesOfInput } from '../../../models/IField'
import * as Yup from 'yup';
import { handleDisableButton } from '../../../plugins/helpers'
import { Regulars } from '../../../constants'

const SignIn = () => {
  const history = useHistory();
  const { login, login2Auth } = useActions()
  const { errors, modalValue } = useTypedSelector(state => state.app);
  const { user } = useTypedSelector(state => state.user);
  const formButtonRef = useRef(null);
  const [remember, setRemember] = useState(false);
  const [formikActions, setFormicActions] = useState<any>(null);

  useEffect(() => {
    if (formikActions && errors && Object.values(errors).length) {
      formikActions.setErrors(errors)
    }
  }, [errors]);

  useEffect(() => {
    if (user?.has_2fa && modalValue) {
      login2Auth(remember)
    }
  }, [modalValue])

  const handleLogin = (values: IUserLogin, actions: any) => {
    setFormicActions(actions);
    handleDisableButton(formButtonRef)
    login({
      data: {
        ...values,
        remember
      },
      history,
    })
  }

  const schema = Yup.object().shape({
    mail: Yup
      .string()
      .matches(Regulars.email.reg, Regulars.email.text)
      .required('Required'),

    password: Yup
      .string()
      .matches(Regulars.password.reg, Regulars.password.text)
      .required('Required'),
  });

  return (
    <section className="login-content">
      <Row className="m-0 align-items-center bg-white vh-100">
        <Col md="6">
          <Row className="justify-content-center">
            <Col md="10">
              <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                <Card.Body>
                  <h2 className="mb-2 text-center">Sign In</h2>
                  <p className="text-center">Login to stay connected.</p>
                  <Formik
                    initialValues={{
                      mail: '',
                      password: '',
                    }}
                    validationSchema={schema}
                    onSubmit={(values, actions) => handleLogin(values, actions)}
                  >
                    <FormicForm>
                      <Row>
                        <FieldWithValidation field={{
                          id: 'mail',
                          required: true,
                          type: TypesOfInput.TEXT,
                          title: 'Email',
                          size: 12,
                          category_id: 1,
                        }} />
                        <FieldWithValidation field={{
                          id: 'password',
                          required: true,
                          type: TypesOfInput.PASSWORD,
                          title: 'Password',
                          size: 12,
                          category_id: 1,
                        }} />
                        <Col lg="12" className="d-flex justify-content-between">
                          <Form.Check className="form-check mb-3">
                            <Form.Check.Input onChange={() => setRemember(!remember)} type="checkbox" id="customCheck1" />
                            <Form.Check.Label htmlFor="customCheck1">Remember Me</Form.Check.Label>
                          </Form.Check>
                          <Link to={AuthRouteNames.RECOVERPW}>Forgot Password?</Link>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-center">
                        <Button
                          ref={formButtonRef}
                          type="submit"
                          variant="primary"
                        >
                          Sign In
                        </Button>
                      </div>
                      <p className="mt-3 text-center">
                        Don’t have an account? <Link to={AuthRouteNames.SIGN_UP} className="text-underline">Click here to sign up.</Link>
                      </p>
                    </FormicForm>
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md="6" className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
          <Image src={auth1} className="Image-fluid gradient-main animated-scaleX" alt="images" />
        </Col>
      </Row>
    </section>
  )
}

export default SignIn
