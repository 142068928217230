import { Card, Col, Row } from 'react-bootstrap'
import React, { FC } from 'react';

import CardOfLocation from './CardOfLocation';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

const wrong = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.8443 14.6667L1.53613 1.33334M1.53613 14.6667L14.8443 1.33334L1.53613 14.6667Z" stroke="#C03221" strokeWidth="2" strokeLinecap="round" />
</svg>

const right = <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.3392 1.83331L5.56968 10.8368C5.07525 11.1601 4.42147 10.9873 4.13517 10.4577L1.04053 4.73315" stroke="#1AA053" strokeWidth="2" strokeLinecap="round" />
</svg>

const LocationsTab: FC = () => {
  const { Locations } = useTypedSelector(state => state.services)

  return (
    <Col md="12">
      <div className="card_of_location__list">
        {Locations.map(location => <CardOfLocation key={location.id} location={location} />)}
      </div>
      <Row className="row-cols-1">
        <Col sm="12">
          <Card>
            <Card.Body>
              <div className="bd-example table-responsive">
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th scope="col">Features</th>
                      {Locations.map(location => <th key={location.id} scope="col">{location.name}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Parking</th>
                      {Locations.map(location =>
                        <td key={location.id}>
                          {
                            location.parking
                              ? right
                              : wrong
                          }
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th scope="row">Kitchen</th>
                      {Locations.map(location =>
                        <td key={location.id}>
                          {
                            location.kitchen
                              ? right
                              : wrong
                          }
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th scope="row">Outdoor Space</th>
                      {Locations.map(location =>
                        <td key={location.id}>
                          {
                            location.outdoor
                              ? right
                              : wrong
                          }
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Col>
  )
}

export default LocationsTab;