
import React, { useEffect } from 'react'
import BookNowFirst from '../../../components/dashboard/book-now/BookNowFirst'
import BookNowSecond from '../../../components/dashboard/book-now/BookNowSecond'
import Checkout from '../../../components/dashboard/book-now/Checkout'
import { useActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'

const Pricing = () => {
  const { selectedService, selectedOrder } = useTypedSelector(state => state.services)
  const { selectService, selectOrder } = useActions()

  useEffect(() => {
    return () => {
      selectService(null)
      selectOrder(null)
    }
  }, [])

  if (selectedOrder) {
    return <Checkout order={selectedOrder} />
  }

  if (selectedService) {
    return <BookNowSecond />
  }

  return <BookNowFirst />
}

export default Pricing
