import { Switch, Route } from 'react-router-dom'
import { IRoute } from '../models/IRoute'
import TermsOfService from '../views/extra/terms-of-service'

export enum ExtraRouteNames {
    TERMS_OF_SERVICE = '/extra/terms-of-service',
}

export const extraRoutes: IRoute[] = [
  { path: ExtraRouteNames.TERMS_OF_SERVICE, exact: true, component: TermsOfService },
]

const Extra = () => {
  return (
    <Switch>
      {extraRoutes.map(route =>
        <Route path={route.path}
          exact={route.exact}
          component={route.component}
          key={route.path}
        />
      )}
    </Switch>
  )
}

export default Extra;
