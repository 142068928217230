import { FC, useEffect } from "react";
import { FieldInputProps, useFormikContext } from "formik";
import { IFieldFront } from "../../../models/IField";

interface CustomProps {
  field: FieldInputProps<any>;
  input: IFieldFront,
}

const Custom: FC<CustomProps> = ({
  field, // { name, value, onChange, onBlur }
  input,
}) => {
  const { setFieldValue, validateField } = useFormikContext() ?? {};

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = ''


    if (!input.formatter && !input.customOnChange) {
      field.onChange(e)
      return;
    }


    if (input.formatter) {
      value = input.formatter(e);
      setFieldValue(field.name, value)
    }
    if (input.customOnChange) {
      input.customOnChange(value)
    }

  }

  useEffect(() => {
    if (!input.dependencies || !field.value) return;
    
    if (input.customOnChange) {
      input.customOnChange(field.value)
    }
    validateField(field.name)
  }, input.dependencies)

  return (
    <div className="input">
      <input
        {...field}
        onChange={handleChangeValue}
        disabled={input.disabled}
        type={input.type}
        placeholder={input.placeholder}
        className="input__field"
      />
    </div>
  )
}


export default Custom;