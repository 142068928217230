import { IErrorResponse, ISuccessResponse } from "../models/IResponse";
import { notificationError } from "../plugins/requestNotification";
import { Axios, mergeHashToHeaders } from "../plugins/helpers";
import { IDeposit, IWithdrawal, IPaymentSystem, TypeOfPaymentsIds, CURRENCY_ID } from "../models/IFinances";

export const GetUserBalance = async (user_id: number): Promise<ISuccessResponse | IErrorResponse> => {
    try {
        const response: ISuccessResponse = await Axios().get(`${process.env.REACT_APP_BASE_URL}/get-user-balance/${user_id}`);
        return response;
    } catch (e: any) {
        notificationError(e)
        return e?.response;
    }
}

export const DepositCreate = async (data: IDeposit): Promise<ISuccessResponse | IErrorResponse> => {
    try {
        const axios = mergeHashToHeaders(data)

        const response: ISuccessResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/deposit-create`, data);
        return response;
    } catch (e: any) {
        notificationError(e)
        return e?.response;
    }
}

export const WithdrawalCreate = async (data: IWithdrawal): Promise<ISuccessResponse | IErrorResponse> => {
    try {
        const response: ISuccessResponse = await Axios().post(`${process.env.REACT_APP_BASE_URL}/withdrawal-create`, data);
        return response;
    } catch (e: any) {
        notificationError(e)
        return e?.response;
    }
}

export const GetPaymentSystem = async (typeOfTransaction: TypeOfPaymentsIds): Promise<ISuccessResponse<IPaymentSystem[]> | IErrorResponse> => {
    try {
        const response: ISuccessResponse<IPaymentSystem[]> = await Axios().get(`${process.env.REACT_APP_BASE_URL}/get-payment-system/${CURRENCY_ID}/type/${typeOfTransaction}`);
        return response;
    } catch (e: any) {
        notificationError(e)
        return e?.response;
    }
}

export const GetUserTransaction = async (user_id: number): Promise<ISuccessResponse | IErrorResponse> => {
    try {
        const response: ISuccessResponse = await Axios().get(`${process.env.REACT_APP_BASE_URL}/get-user-transaction/${user_id}`);
        return response;
    } catch (e: any) {
        notificationError(e)
        return e?.response;
    }
}