import { useTypedSelector } from "../hooks/useTypedSelector"
import loader from '../assets/images/logo.svg'
import { useEffect, useState } from "react";

const Loader = () => {
  const { showLoader } = useTypedSelector(state => state.app)
  const [hideLoader, setHideLoader] = useState(false);
  const [hideIndex, setHideIndex] = useState(false);

  useEffect(() => {
    if (!showLoader) {
      setTimeout(() => {
        setHideLoader(true);
        setTimeout(() => {
          setHideIndex(true);
        }, 500)
      }, 100);
      return;
    }
    setHideLoader(false)
    setHideIndex(false);
  }, [showLoader]);

  return (
    <div className={`loader
    ${hideLoader ? ' loader--hide' : ''}
    ${hideIndex ? ' loader--zindex' : ''}`
    }>
      <img src={loader} alt="loader" />
    </div>
  )
}

export default Loader;