import React, { FC, useState, useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { FinancesFields, LocalObject } from "../../constants";
import { ObjectShape } from "yup/lib/object";
import FieldWithValidation from "./FieldWithValidation";
import { TypesOfInput } from "../../models/IField";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { CURRENCY_ID, IPaymentSystem, TypeOfPayments } from "../../models/IFinances";
import { useActions } from "../../hooks/useActions";
import { createRulesForField } from "../../apis/fields";

interface PaymentModalProps {
  type: TypeOfPayments
}

const PaymentModal: FC<PaymentModalProps> = ({ type }) => {
  const { user, finances } = useTypedSelector(state => state.user)
  const { paymentSystems, repeatingTransaction } = useTypedSelector(state => state.profile)
  const { createTransaction, fetchPaymentSystems, handleLoader } = useActions()

  const [commission, setCommission] = useState<number>(0);
  const [method, setMethod] = useState<IPaymentSystem>(LocalObject);
  const [total, setTotal] = useState<string | number>('');

  const [amount, setAmount] = useState<string>('');
  const [validations, setValidations] = useState<ObjectShape>({});

  useEffect(() => {
    if (!paymentSystems) {
      handleLoader(true)
      fetchPaymentSystems()
    }
  }, [])

  useEffect(() => {
    setMethod(LocalObject)
  }, [type])

  useEffect(() => {
    if (paymentSystems && repeatingTransaction) {
      const selectedMethod = paymentSystems[type].find(el => el.payment_id === repeatingTransaction.payment_system)

      if (selectedMethod) {
        setMethod(selectedMethod)
        setAmount(repeatingTransaction.amount + '')
      }
    }
  }, [type, repeatingTransaction])

  useEffect(() => {
    generateValidations();
    calculateCommissionByType(method)
  }, [method, type])

  const generateValidations = () => {
    const currentFields = FinancesFields?.[type]?.[method.payment_id];
    let schema = {}

    if (currentFields) {
      const listOfFields = currentFields.map(field => field.id);
      schema = createRulesForField(listOfFields, currentFields);
    }

    if (type === TypeOfPayments.DEPOSIT) {
      schema = {
        ...schema,
        amount: Yup
          .number()
          .min(method.deposit_min, `From ${method.deposit_min} to ${method.deposit_max}`)
          .max(method.deposit_max, `From ${method.deposit_min} to ${method.deposit_max}`)
          .required('Required'),
      }
    } else {
      schema = {
        ...schema,
        amount: Yup
          .number()
          .min(method.withdrawal_min, `From ${method.withdrawal_min} to ${method.withdrawal_max}`)
          .max(method.withdrawal_max, `From ${method.withdrawal_min} to ${method.withdrawal_max}`)
          .required('Required'),
      }
    }

    setValidations(schema)
  }

  const handleChangeMethod = (item: IPaymentSystem) => {
    setMethod(item)
  }

  const handleChangeAmount = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    let digitValue = value.replace(/[^\d.]/g, '');
    const dotIndex = digitValue.indexOf('.')
    const zeroIndex = digitValue.indexOf('0')
    if (zeroIndex > -1 && zeroIndex === 0 && digitValue.length > 1) {
      digitValue = parseFloat(digitValue) + '.'
    }
    if (dotIndex > -1) {
      if (dotIndex === 0) {
        digitValue = digitValue.replace(/[^\d]/g, '0.')
      } else {
        digitValue = digitValue.substring(0, dotIndex + 1) + digitValue.substring(dotIndex, dotIndex + 3).replace(/[^\d]/g, '',)
      }
    }

    return digitValue;
  }

  const calculateAmount = (value: number) => {
    if (commission) {
      const commissionValue = value * commission / 100
      if (type === TypeOfPayments.DEPOSIT) {
        setTotal((value + commissionValue).toFixed(2));
      } else {
        setTotal((value - commissionValue).toFixed(2));
      }
    } else {
      setTotal(value.toFixed(2));
    }
  };

  const calculateCommissionByType = (item?: IPaymentSystem) => {
    if (item) {
      if (type === TypeOfPayments.DEPOSIT) {
        setCommission(item.deposit_fee)
      } else {
        setCommission(item.withdrawal_fee)
      }
      return;
    }

    if (type === TypeOfPayments.DEPOSIT) {
      setCommission(method.deposit_fee)
    } else {
      setCommission(method.withdrawal_fee)
    }
  }

  const handleSubmit = async (values: IInitialValues, actions: any) => {
    if (!user) return;

    createTransaction({
      ...values,
      amount: Number(values.amount),
      currency_id: CURRENCY_ID,
      payment_system: method.payment_id,
      type,
    })

    actions.resetForm({
      values: {
        ...initialValues, 
        amount: '',
      },
    });
    setTotal('')
    setMethod(LocalObject)
  };

  const schema = Yup.object().shape(validations);
  interface IInitialValues {
    amount: string,
    wallet: string,
    card_holder: string,
    card_number: string,
    expiration_date: string,
    cvv: string,
  }

  const initialValues = {
    wallet: '',
    card_holder: '',
    card_number: '',
    expiration_date: '',
    cvv: '',
    amount,
  }

  return <div className='payment-modal'>
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      <Form>
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">{type === TypeOfPayments.WITHDRAWAL ? 'Withdraw' : 'Deposit'}</h4>
            </div>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col lg="6">
                <Row className="mb-3">
                  <Col lg="4" className="d-flex align-items-center">
                    Method:
                  </Col>
                  <Col lg="8">
                    {paymentSystems?.[type].map((item: IPaymentSystem) =>
                      <div className="form-check" key={item.payment_id}>
                        <input
                          checked={item.payment_id === method.payment_id}
                          value={item.payment_id}
                          onChange={() => handleChangeMethod(item)}
                          id={item.payment_name}
                          type="radio"
                          name="radios"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor={item.payment_name}
                        >
                          {item.payment_name}
                        </label>
                      </div>
                    )}
                  </Col>
                </Row>
                {FinancesFields[type][method.payment_id]?.map(field => (
                  <Row key={field.id}>
                    <Col lg="4" className="d-flex align-items-center">
                      {field.title}:
                    </Col>
                    <FieldWithValidation field={{ ...field, title: '' }} />
                  </Row>
                ))}
                <Row className="mb-3">
                  <Col lg="4" className="d-flex align-items-center">
                    Amount:
                    </Col>
                  <FieldWithValidation
                    field={{
                      id: 'amount',
                      required: true,
                      size: 4,
                      type: TypesOfInput.TEXT,
                      category_id: 1,
                      placeholder: "0.00",
                      customOnChange: (value) => calculateAmount(Number(value)),
                      formatter: (event) => handleChangeAmount(event),
                      dependencies: [schema],
                    }}
                  />
                  {type === TypeOfPayments.WITHDRAWAL &&
                    <Col className="payment-modal__commision">
                      <div className="payment-modal__commision--amount">$ {finances?.balance}</div>
                  Avalible to withdrow
                  </Col>
                  }
                </Row>
                <Row>
                  <Col lg="4" className="d-flex align-items-center">
                    Total To Enroll:
                    </Col>
                  <Col lg="4">
                    <input
                      value={total}
                      disabled
                      type="text"
                      className="input__field"
                      placeholder="0.00"
                    />
                  </Col>
                  <Col className="payment-modal__commision">
                    <div className="payment-modal__commision--amount">{commission} %</div>
                      With commission
                  </Col>
                </Row>
              </Col>
              <Col lg={{
                span: 5,
                offset: 1
              }}>
                <div className="drawerTop__text">
                  <p className="drawerTop__text--title">
                    Check the recipient's card
                  </p>
                  <p className="drawerTop__text--text">
                    Carefully check the card number to which you are sending the transfer. If the money goes to the wrong person, there is no guarantee that it will be returned.
                  </p>
                  <a href="https://vtinbox.com/terms" target="_blank" rel="noreferrer">All terms of user</a>
                </div>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Button
              type="submit"
              variant="primary"
              className="mr-3"
            >
              {type === TypeOfPayments.WITHDRAWAL ? 'Withdraw Now' : 'Deposit Now'}
            </Button>
          </Card.Footer>
        </Card>
      </Form>
    </Formik>
  </div>
}

export default PaymentModal;