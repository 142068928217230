import { Button, Card, Col, Modal } from "react-bootstrap";
import React, { FC, useState } from "react";
import { IOrder } from "../../../models/IBookNow";
import locationImage from "../../../assets/images/location.png";
import { notification } from "antd";

// icons
import peopleIcon from "../../../assets/images/icons/team.svg";
import calendarIcon from "../../../assets/images/icons/calendar.svg";
import environmentIcon from "../../../assets/images/icons/environment.svg";
import { useActions } from "../../../hooks/useActions";
import CheckoutOrder from "./CheckoutOrder";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
interface CardOfOrderProps {
  order: IOrder;
}

const MaxLengthOfDescription = 114;

const CardOfOrder: FC<CardOfOrderProps> = ({ order }) => {
  const { selectOrder } = useActions();
  const [showModal, setShowModal] = useState<IOrder | null>(null);
  const { filters } = useTypedSelector((state) => state.services);

  const handleSelect = () => {
    if (!(filters.startDate && filters.endDate)) {
      notification.info({
        message:
          "To select this service you need to add the start date and end date of the service",
      });
    } else {
      selectOrder(order);
    }
  };

  const renderDescription = () => {
    if (order.description.length > MaxLengthOfDescription) {
      return order.description.match(/.{1,114}/g)?.[0].trim() + "...";
    }
    return order.description;
  };

  const handleShowModal = () => {
    setShowModal(order);
  };

  const handleClose = () => {
    setShowModal(null);
  };

  return (
    <>
      <Modal
        className="transaction_modal"
        show={!!showModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {showModal && <CheckoutOrder order={showModal} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Col lg="12">
        <Card className="mb-4 rounded-3 shadow-sm">
          <Card.Body className="d-flex card_of_order">
            <img
              className="card_of_order__img"
              src={locationImage}
              alt="location"
            />
            <div className="card_of_order__content">
              <div className="card_of_order__info">
                <div>
                  <Card.Title as="h2" className="card_of_service__name">
                    {order.name}
                  </Card.Title>
                  <div className="card_of_order__locations">
                    <img src={environmentIcon} alt="environment" />
                    {order.location} • {order.available}
                  </div>
                </div>
                <p className="card_of_order__description">
                  {renderDescription()}
                  {order.description.length > MaxLengthOfDescription && (
                    // eslint-disable-next-line
                    <a href="#" onClick={handleShowModal}>
                      read more
                    </a>
                  )}
                </p>
                <div className="card_of_order__extrainfo">
                  <div>
                    <img src={peopleIcon} alt="people" />
                    <span className="card_of_service__info">
                      {order.participants}
                    </span>
                  </div>
                  <div>
                    <img src={calendarIcon} alt="calendar" />
                    <span className="card_of_service__info">
                      {order.timetable}
                    </span>
                  </div>
                </div>
              </div>
              <div className="card_of_order__price">
                <span>${order.price}</span>
                <Button variant="primary" onClick={handleSelect}>
                  Book Now
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default CardOfOrder;
