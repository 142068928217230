import React, { FC, useState, useEffect } from "react";
import { useFormikContext, FieldInputProps } from "formik";
import { IField } from "../../../models/IField";

interface PhoneProps {
  field: FieldInputProps<any>;
  input: IField,
}

const Phone: FC<PhoneProps> = ({
  field, // { name, value, onChange, onBlur }
  input,
}) => {
  const { setFieldValue, values, errors } = useFormikContext() ?? {};
  const [phoneCode, setPhoneCode] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPhoneCode(e.target.value)
    setFieldValue('phone_code', e.target.value)
  }

  useEffect(() => {
    setPhoneCode((values as any)['phone_code'])
  }, [])

  return (
    <>
      <div className="input input--phone">
        <select
          {...field}
          name="phone_code"
          className="input__field input__field--select"
          onChange={handleChange}
          value={phoneCode}
        >
          {input.options && input.options.map(option =>
            <option key={option.key} value={option.key}>{option.value}</option>
          )}
        </select>
        <input
          {...field}
          className="input__field input__field--input"
        />
      </div>
      {!(errors as any)['phone'] && (errors as any)['phone_code'] &&
        <p className="error">
          {(errors as any)['phone_code']}
        </p>
      }
    </>
  )
}


export default Phone;