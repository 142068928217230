import { Card, Col, Nav, Row, Tab } from 'react-bootstrap'
import React, { FC, useState } from 'react';
import { TypeBookNow } from '../../../models/IBookNow';

import LocationsTab from './LocationsTab';
import ServicesTab from './ServicesTab';

const BookNowFirst: FC = () => {
  const [tabKey, setTabKey] = useState<TypeBookNow>(TypeBookNow.SERVICES);
  
  return (
    <Row>
      <Tab.Container>
        <Col lg="12">
          <Card>
            <Card.Body>
              <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab">
                <Nav.Item as="li" onClick={() => setTabKey(TypeBookNow.SERVICES)}>
                  <Nav.Link className={`${tabKey === TypeBookNow.SERVICES ? 'active' : ''}`}>{TypeBookNow.SERVICES}</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" onClick={() => setTabKey(TypeBookNow.BUILDINGS)}>
                  <Nav.Link className={`${tabKey === TypeBookNow.BUILDINGS ? 'active' : ''}`}>{TypeBookNow.BUILDINGS}</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Body>
          </Card>
        </Col>
      </Tab.Container>
      {tabKey === TypeBookNow.SERVICES
        ? <ServicesTab />
        : <LocationsTab />
      }
    </Row>
  )
}

export default BookNowFirst;