import { FC, useEffect, useState } from "react";
import { FieldInputProps, FormikProps } from "formik";
import { IFieldFront, IFieldOption } from "../../../models/IField";
import { GetRegionsOptions } from "../../../apis/fields";
import { IUserInfo } from "../../../models/IUser";

interface SelectProps {
  field: FieldInputProps<any>;
  input: IFieldFront,
  form: FormikProps<IUserInfo> | any,
}

const SelectRegions: FC<SelectProps> = ({
  input,
  field, // { name, value, onChange, onBlur }
  form,
}) => {
  const [options, setOptions] = useState<IFieldOption[]>([]);

  let dependency = ''

  if (input.dependency && input.dependency in form.values) {
    dependency = form.values[input?.dependency]
  }

  useEffect(() => {
    setOptions([{
      value: field.value,
      key: field.value,
    }])
  }, [])
  
  useEffect(() => {
    if (dependency && options.length <= 1) {
      fetchRegions()
    }
  }, [dependency]);

  const fetchRegions = async () => {
    const regions: IFieldOption[] | undefined = await GetRegionsOptions(dependency)
    if (regions) {
      setOptions(regions)
    }
  }

  return (
    <div className="input">
      <select
        {...field}
        className="input__field"
      >
        {options.map((option: IFieldOption) =>
          <option key={option.key} value={option.key}>{option.value}</option>
        )}
      </select>
    </div>
  )
}


export default SelectRegions;