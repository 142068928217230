export interface IDeposit extends ITransaction {
    cardholder?: string,
    card?: string,
    wallet?: string,
    user_id: number,
}

export interface IWithdrawal extends ITransaction {
    wallet?: string,
    user_id: number,
}
export interface ITransaction {
    type: TypeOfPayments,
    currency_id: number,
    payment_system: number,
    amount: number,
}
export enum TypeOfPayments {
    WITHDRAWAL = 'withdrawal',
    DEPOSIT = 'deposit',
}

export enum TypeOfPaymentsIds {
    DEPOSIT = 1,
    WITHDRAWAL = 2
}
export type IPaymentSystemsObj = {
    [key in TypeOfPayments]: IPaymentSystem[];
};
export interface IPaymentSystem {
    icon?: string
    deposit_fee: number
    deposit_max: number
    deposit_min: number
    payment_id: number
    payment_name: string
    withdrawal_fee: number
    withdrawal_max: number
    withdrawal_min: number
}

export const CURRENCY_ID = 1;