//img
import topHeader from '../../assets/images/dashboard/top-header.png'

const SubHeader = () => {
    return (
        <div className="iq-navbar-header" style={{ height: "215px" }}>
            <div className="iq-header-img">
                <img src={topHeader} alt="header" className="img-fluid w-100 h-100 animated-scaleX" />
            </div>
        </div>
    )
}

export default SubHeader
