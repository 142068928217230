import * as types from './types';

const initialState: types.AppState = {
    errors: {},
    showLoader: true,
    isShowModal: false,
    modalValue: '',
    modalTitle: '',
    modalDescription: '',
    modalImage: '',
}

export const apphReducer = (state = initialState, action: types.AppAction): types.AppState => {
    switch (action.type) {
        case types.AppActionEnum.SET_ERRORS:
            return { ...state, errors: action.payload };

        case types.AppActionEnum.HANDLE_LOADER:
            return { ...state, showLoader: action.payload };

        case types.AppActionEnum.HANDLE_SHOW_MODAL:
            return { 
                ...state,
                isShowModal: true,
                modalTitle: action.payload.title,
                modalDescription: action.payload.description,
                modalImage: action.payload.image,
             }

        case types.AppActionEnum.HANDLE_HIDE_MODAL:
            return { 
                ...state,
                isShowModal: false,
                modalValue: action.payload,
                modalTitle: '',
                modalDescription: '',
                modalImage: '',
             }

        default:
            return state;
    }
}

export default apphReducer;