import { Card, Col, Row } from 'react-bootstrap'
import React, { FC } from 'react';

import CardOfService from './CardOfService';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

const wrong = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.8443 14.6667L1.53613 1.33334M1.53613 14.6667L14.8443 1.33334L1.53613 14.6667Z" stroke="#C03221" strokeWidth="2" strokeLinecap="round" />
</svg>

const right = <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.3392 1.83331L5.56968 10.8368C5.07525 11.1601 4.42147 10.9873 4.13517 10.4577L1.04053 4.73315" stroke="#1AA053" strokeWidth="2" strokeLinecap="round" />
</svg>

const ServicesTab: FC = () => {
  const { TypeOfServices } = useTypedSelector(state => state.services)
  
  return (
    <Col md="12">
      <Row className=" row-cols-1 row-cols-md-2 row-cols-lg-4 mb-3 text-center">
        {TypeOfServices.map(service => <CardOfService key={service.name} service={service} />)}
      </Row>
      <Row className=" row-cols-1">
        <Col sm="12">
          <Card>
            <Card.Body>
              <div className="bd-example table-responsive">
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th scope="col">Features</th>
                      {TypeOfServices.map(service => <th key={service.id} scope="col">{service.name}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">People</th>
                      {TypeOfServices.map(service => <td key={service.id}>{service.people}</td>)}
                    </tr>
                    <tr>
                      <th scope="row">Price</th>
                      {TypeOfServices.map(service => <td key={service.id}>${service.price}</td>)}
                    </tr>
                    <tr>
                      <th scope="row">Features 1</th>
                      {TypeOfServices.map(service =>
                        <td key={service.id}>{
                          service.features1
                            ? wrong
                            : right
                        }
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th scope="row">Features 2</th>
                      {TypeOfServices.map(service =>
                        <td key={service.id}>{
                          service.features2
                            ? wrong
                            : right
                        }
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th scope="row">Features 3</th>
                      {TypeOfServices.map(service =>
                        <td key={service.id}>{
                          service.features3
                            ? wrong
                            : right
                        }
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Col>
  )
}

export default ServicesTab;