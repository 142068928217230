import {AuthActionCreators} from "./auth/action-creators";
import { AppActionCreators } from './app/action-creators';
import { UserActionCreators } from './user/action-creators';
import { ProfileActionCreators } from './profile/action-creators';
import { ServicesActionCreators } from './services/action-creators';

export const allActionCreators = {
    ...AuthActionCreators,
    ...AppActionCreators,
    ...UserActionCreators,
    ...ProfileActionCreators,
    ...ServicesActionCreators,
}
