import { IErrorResponse, ISuccessResponse } from "../models/IResponse";
import { IUser2Auth, IUserGlobal, IUserLogin, IUserRegister, IUserResetPassword } from "../models/IUser"
import { notificationError } from "../plugins/requestNotification";
import { IFilledField } from '../models/IField';
import { Axios } from "../plugins/helpers";

export const UserInit = async (): Promise<ISuccessResponse<{ user_id: string }> | IErrorResponse> => {
    try {
        const response: ISuccessResponse<{ user_id: string }> = await Axios().get(`${process.env.REACT_APP_BASE_URL}/user/identification`);
        return response;
    } catch (e: any) {
        // notificationError(e)
        return e?.response;
    }
}

export const UserLogin = async (user: IUserLogin): Promise<ISuccessResponse<any> | IErrorResponse> => {
    try {
        const response: ISuccessResponse<any> = await Axios().post(`${process.env.REACT_APP_BASE_URL}/user/login`, user);
        return response;
    } catch (e: any) {
        // notificationError(e)
        return e?.response;
    }
}

export const UserLogout = () => {
    try {
        Axios().get(`${process.env.REACT_APP_BASE_URL}/user/logout`);
    } catch (e: any) {
        notificationError(e)
    }
}

export const User2Auth = async (auth: IUser2Auth): Promise<ISuccessResponse<any> | IErrorResponse> => {
    try {
        const response: ISuccessResponse<{
            auth_token: string,
            refresh_token: string,
        }> = await Axios().post(`${process.env.REACT_APP_BASE_URL}/check-two-factor`, auth);
        return response;
    } catch (e: any) {
        notificationError(e)
        return e?.response;
    }
}

export const ResetPasswordEmail = async (data: { mail: string }): Promise<ISuccessResponse<any> | IErrorResponse> => {
    try {
        const response: ISuccessResponse<any> = await Axios().post(`${process.env.REACT_APP_BASE_URL}/forgot-password`, data);
        return response;
    } catch (e: any) {
        notificationError(e)
        return e?.response;
    }
}

export const ResetPasswordUpdate = async (data: IUserResetPassword): Promise<ISuccessResponse<any> | IErrorResponse> => {
    try {
        const response: ISuccessResponse<any> = await Axios().post(`${process.env.REACT_APP_BASE_URL}/check-password-recovery`, data);
        return response;
    } catch (e: any) {
        notificationError(e)
        return e?.response;
    }
}

export const FetchUser = async (user_id: number): Promise<ISuccessResponse<IUserGlobal> | null> => {
    try {
        const response: ISuccessResponse<IUserGlobal> = await Axios().get(`${process.env.REACT_APP_BASE_URL}/user/${user_id}`);
        return response;
    } catch (e: any) {
        notificationError(e)
        return null;
    }
}

export const UserRegister = async (user: IUserRegister): Promise<ISuccessResponse<any> | IErrorResponse> => {
    try {
        const sendingFields: IFilledField = {};

        Object.entries(user.fields).forEach((value: [string, any]) => {
            if (value[1] instanceof Date) {
                sendingFields[value[0]] = value[1].getTime()
            } else if (typeof value[1] === 'boolean') {
                sendingFields[value[0]] = Number(value[1])
            } else {
                sendingFields[value[0]] = value[1]
            }
        })

        const response: ISuccessResponse<any> = await Axios().post(`${process.env.REACT_APP_BASE_URL}/user`, {
            ...user, fields: sendingFields
        });

        return response;
    } catch (e: any) {
        notificationError(e)
        return e?.response;
    }
}