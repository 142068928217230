import * as types from './types';

const initialState: types.UserState = {
    user: null,
    finances: null,
}

export const userReducer = (state = initialState, action: types.UserAction): types.UserState => {
    switch (action.type) {
        case types.UserActionEnum.UPDATE_USER:
            if (!action.payload) {
                return {
                    ...state,
                    user: action.payload
                }
            }
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload,
                }
            }

        case types.UserActionEnum.UPDATE_FINANCES:
            if (!action.payload) {
                return {
                    ...state,
                    finances: action.payload
                }
            }
            return {
                ...state,
                finances: {
                    ...state.finances,
                    ...action.payload,
                }
            }

        default:
            return state;
    }
}

export default userReducer;
