import React from 'react'


const Logo = ({ src }: { src: string }) => {
    return (
        <img className="logo" src={src} alt="logo" />
    )
}

export default Logo
