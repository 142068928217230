import { FC } from "react";
import { FieldInputProps } from "formik";
import { IFieldFront } from "../../../models/IField";


interface CheckboxProps {
  field: FieldInputProps<any>;
  input: IFieldFront,
}

const Checkbox: FC<CheckboxProps> = ({
  field,
  input,
}) => {

  return (
    <div>
      <input
        {...field}
        type={input.type}
      />
    </div>
  )
}

export default Checkbox;